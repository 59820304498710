import clsx from "clsx";

import { Image } from "@/components";

import styles from "./BodyChangeSection.module.scss";
import { Descriptions } from "./Descriptions";

interface Image {
  src: string;
  aspectRatio: string;
}

export interface BodyChangeSectionProps {
  title: string;
  greatValue: Image;
  convenience: Image;
  fun: Image;
  isImage?: boolean;
  isBgYellow?: boolean;
}

const descriptions = {
  discount: [
    <>
      初回20%OFF、2回目以降10%OFF
      <sup className="sup">※1</sup>
    </>,
    <>
      次回の購入に使える200円分のポイントプレゼント
      <sup className="sup">※2</sup>
    </>,
    <>
      BASE Cookies
      <sup className="sup">
        <i className="far fa-registered fa-fw"></i>
      </sup>
      ココナッツ1袋無料プレゼント（初回のみ）
    </>,
  ],
  convenient: [
    "毎月のご注文が不要、4週間ごとにお届け",
    <>
      お届け日時の指定/変更可能
      <sup className="sup">※3</sup>
    </>,
  ],
  fun: [
    "管理栄養士に質問できるオンラインコミュニティにご招待",
    "月刊ジャーナルでレシピやここだけの情報をお届け",
  ],
};

export function BodyChangeSection({
  title,
  greatValue,
  convenience,
  fun,
  isImage = false,
  isBgYellow = false,
}: BodyChangeSectionProps): React.ReactNode {
  return (
    <section className={`pd__top__xl pd__bottom__xl ${isBgYellow ? "bg__yellow" : "bg__gray"}`}>
      <div className="container">
        <div className="row">
          <div className="col-12 col-m-10 col-offset-m-1">
            <div className="text__center mg__bottom__l">
              <h3 className="text__xl text__bold">
                <span className="wsnr">続けると、</span>
                <wbr />
                <span className="wsnr">{title}</span>
              </h3>
            </div>
            <p className="text__l text__justify mg__bottom__l">
              食事は続けることで、からだに変化が生まれてきます。体に良い食事を、お得にむりなく続けられる「BASE
              FOOD
              <sup className="sup">
                <i className="far fa-registered fa-fw"></i>
              </sup>
              継続コース」が選ばれています。
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-m-4 mg__bottom__l mg__bottom__off__pc">
            <div className={styles.section}>
              <p className="text__bold text__xl text__center">お得</p>
              <Image
                src={greatValue.src}
                alt="お得"
                containerClassName={clsx("mg__top__m mg__bottom__m", styles.image)}
                aspectRatio={greatValue.aspectRatio}
              />
              <Descriptions descriptions={descriptions.discount} useIcon={isImage} />
            </div>
          </div>
          <div className="col-12 col-m-4 mg__bottom__l mg__bottom__off__pc">
            <div className={clsx(styles.section, styles.fullHeight)}>
              <p className="text__bold text__xl text__center">便利</p>
              <Image
                src={convenience.src}
                alt="便利"
                containerClassName={clsx("mg__top__m mg__bottom__m", styles.image)}
                aspectRatio={convenience.aspectRatio}
              />
              <Descriptions descriptions={descriptions.convenient} useIcon={isImage} />
            </div>
          </div>
          <div className="col-12 col-m-4">
            <div className={styles.section}>
              <p className="text__bold text__xl text__center">たのしい</p>

              <Image
                src={fun.src}
                alt="たのしい"
                containerClassName={clsx("mg__top__m mg__bottom__m", styles.image)}
                aspectRatio={fun.aspectRatio}
              />
              <Descriptions descriptions={descriptions.fun} useIcon={isImage} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-m-10 col-offset-m-1">
            <p className="text__xl text__justify mg__top__l">
              また、継続コースなら、1回目お届け以降、いつでもお届け日変更・解約可能！契約のしばりは一切ありません。
              <sup className="sup">※3</sup>
            </p>
            <p className="text__s mg__top__l">
              ※1 BASE FOOD
              <sup className="sup">
                <i className="far fa-registered fa-fw"></i>
              </sup>
              継続コース（旧定期便含む）をはじめてご注文の場合、初回20%OFF。
              <br />
              ※2 ポイントの有効期限は初回注文から45日間です。
              <br />
              ※3 配送予定日の5日前まで手続き可能。
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
