import clsx from "clsx";

import { ProductModel } from "@/models/product/type";
import { formatCurrency } from "@/utils/currency";

import styles from "./UnitPrice.module.scss";

export interface UnitPriceProps {
  product: ProductModel;
  isSubscription: boolean;
}

export function UnitPrice({ product, isSubscription }: UnitPriceProps): React.ReactNode {
  return (
    <>
      {isSubscription ? (
        <>
          <span
            className={clsx("text__s text__gray__dark text__normal text__left", styles.normalPrice)}
          >
            {formatCurrency(product.normalPrice ?? 0)}
          </span>
          <span className={clsx("text__m text__bold text__red", styles.subscriptionPrice)}>
            {formatCurrency(product.firstSubscriptionPrice ?? 0)}
          </span>
        </>
      ) : (
        <span className="text__m text__bold price__single">
          {formatCurrency(product.normalPrice ?? 0)}
        </span>
      )}
      <span className={clsx("text__s text__gray__dark text__normal", styles.meal)}>
        /{product.meal}
      </span>
    </>
  );
}
