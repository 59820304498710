import React from "react";

import clsx from "clsx";

import styles from "./CircularProgressBar.module.scss";

interface CircularProgressBarProps {
  progress: number;
  barClassName?: string;
  bgColor?: string;
  barColor?: string;
}

export function CircularProgressBar({
  progress,
  barClassName,
  bgColor,
  barColor,
}: CircularProgressBarProps) {
  const radius = 40;
  const strokeWidth = 8;
  const normalizedRadius = radius - strokeWidth * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (progress / 100) * circumference;

  return (
    <svg height={radius * 2} width={radius * 2} className={styles.container}>
      <circle
        stroke={bgColor || "#ffffff"}
        fill="transparent"
        strokeWidth={strokeWidth}
        r={normalizedRadius}
        cx={radius}
        cy={radius}
        className={styles.circle}
      />
      <circle
        stroke={barColor || "#00bfff"}
        fill="transparent"
        strokeWidth={strokeWidth}
        strokeDasharray={`${circumference} ${circumference}`}
        style={{ strokeDashoffset }}
        strokeLinecap="round"
        r={normalizedRadius}
        cx={radius}
        cy={radius}
        className={clsx(styles.circle, barClassName)}
      />
    </svg>
  );
}
