import { useMemo } from "react";

import clsx from "clsx";

import { UnitPrice } from "@/components";
import { Image } from "@/components/displays";
import { ProductModel } from "@/models/product/type";
import { formatCurrency } from "@/utils/currency";

import styles from "./ProductListItem.module.scss";

export interface ProductListItemProps {
  product: ProductModel;
  quantity: number;
  isSubscription: boolean;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  onClickDeletion: (event: React.MouseEvent) => void;
}

export function ProductListItem({
  product,
  quantity,
  isSubscription,
  onChange,
  onClickDeletion,
}: ProductListItemProps): React.ReactNode {
  const subTotalPrice = useMemo(
    () =>
      (product.subscription ? product.firstSubscriptionTotalPrice : product.normalTotalPrice) ?? 0,
    [product]
  );

  return (
    <div className={styles.container} key={product.variantId}>
      <div className={styles.detailColumn}>
        <div className={styles.imageContainer}>
          {product.images && product.images.length > 0 && (
            <Image
              src={product.images[product.variantImageIndex ?? 0] ?? ""}
              alt={product.title ?? ""}
              size={{ width: 80, height: 80 }}
              className={styles.image}
            />
          )}
        </div>

        <div className={styles.title}>
          <div className="text__m">
            {product.title}
            <br />
            {product.meal !== "組" && product.variantTitle}
          </div>
          <div className="text__left clear__pc">
            <UnitPrice product={product} isSubscription={isSubscription} />
          </div>
        </div>
      </div>

      <div className={styles.priceColumn}>
        <div className={clsx(styles.selection, "mg__top__s mg__bottom__s")}>
          <div className="clear__sp">
            <UnitPrice product={product} isSubscription={isSubscription} />
          </div>

          <select value={quantity} onChange={onChange} className={styles.quantity}>
            {product.selectOptions?.map((option) => <option key={option}>{option}</option>)}
          </select>

          <span className="text__m">{product.meal}</span>

          <span
            className="btn gray inline round text__black mini text__bold"
            onClick={onClickDeletion}
          >
            削除
          </span>
        </div>

        <div
          className={clsx(
            "text__right text__bold text__l",
            styles.totalPrice,
            isSubscription && "text__red"
          )}
        >
          {formatCurrency(subTotalPrice)}
        </div>
      </div>
    </div>
  );
}
