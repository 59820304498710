import React from "react";

import clsx from "clsx";

import styles from "./Button.module.scss";

type ButtonProps<T extends React.ElementType> = {
  as?: T;
  variants: "primary" | "secondary" | "yellow" | "blue" | "gray" | "darkblue" | "outlined";
  fullWidth?: boolean;
  rounded?: boolean;
  size?: "sm" | "md";
  className?: string;
} & React.ComponentPropsWithoutRef<T>;

export function Button<T extends React.ElementType = "button">({
  as,
  children,
  variants,
  fullWidth = false,
  rounded = false,
  size = "md",
  className,
  ...rest
}: ButtonProps<T>): React.ReactElement | null {
  const Component = as || "button";

  return (
    <Component
      className={clsx(
        styles[variants],
        rounded ? styles.rounded : styles.square,
        fullWidth && styles.fullWidth,
        styles[size],
        className
      )}
      {...rest}
    >
      {children}
    </Component>
  );
}
