import clsx from "clsx";

import { Image } from "@/components/displays";

import styles from "./FirstSpecialBenefitsSection.module.scss";

export function FirstSpecialBenefitsSection(): React.ReactNode {
  return (
    <>
      <div className="row">
        <div className={"col-12 col-m-4 col-offset-m-4 mg__bottom__m mg__top__l text__center"}>
          <Image
            src="https://asset.basefood.co.jp/images/bnr/bnr_merit_ttl.png"
            alt="初めての方限定２つの初回特典"
            aspectRatio="832 / 231"
            containerClassName={clsx(styles.image, styles.fullImages, styles.meritTtl)}
          />
        </div>
      </div>
      <div className="row">
        <div className={"col-12 col-m-4  col-offset-m-2 mg__bottom__m"}>
          <Image
            src="https://asset.basefood.co.jp/images/bnr/bnr_merit_01_0115.png"
            alt="初めての方限定２つの初回特典"
            aspectRatio="644 / 359"
            containerClassName={clsx(styles.image, styles.fullImages)}
          />
        </div>
        <div className={"col-12 col-m-4 mg__bottom__m"}>
          <Image
            src="https://asset.basefood.co.jp/images/bnr/bnr_merit_02_0805.png"
            alt="初めての方限定２つの初回特典"
            aspectRatio="1316 / 501"
            containerClassName={clsx(styles.image, styles.fullImages)}
          />
        </div>
      </div>
    </>
  );
}
