import { Stripe } from "@stripe/stripe-js";
import pRetry from "p-retry";

let stripe: Stripe | null = null;

export const loadStripe = async () => {
  if (stripe) return stripe;
  // 動的インポートにより、テスト環境でのエラーを回避
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { loadStripe: _loadStripe } = require("@stripe/stripe-js");
  const res = await pRetry(() => _loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY!, {}), {
    retries: 3,
  });
  stripe = res!;
  return res!;
};
