"use client";
import React, { useState, useCallback, useEffect } from "react";

import clsx from "clsx";
import Link from "next/link";
import { CSSTransition } from "react-transition-group";

import { MiniLinkButton } from "@/components/buttons";
import { Row } from "@/components/containers";
import { Button, Input, RadioButtonGroup } from "@/components/inputs";
import { BasicParagraph } from "@/components/texts";
import { UsePointType } from "@/generated/open-api/schemas";
import { CartModel } from "@/models/cart/type";
import { convertToWebpUrl, numberFormat } from "@/utils";
import { formatCurrency } from "@/utils/currency";

import { OrderItem } from "./OrderItem/OrderItem";
import styles from "./OrderSummary.module.scss";
import { useAuth } from "../../auth";

interface OrderSummaryProps {
  cart?: CartModel;
  onChangeCoupon: (coupon: string | null) => void;
  onChangePoint: (points: number) => void;
  currentPoints: number;
}

const lang = process.env.NEXT_PUBLIC_LANG;

const pointOptions = [
  {
    value: "all",
    label: "すべてのポイントを利用する",
  },
  {
    value: "none",
    label: "ポイントを利用しない",
  },
] as const;

export function OrderSummary({
  cart,
  currentPoints,
  onChangeCoupon,
  onChangePoint,
}: OrderSummaryProps): React.ReactNode {
  const activeCouponCode = cart?.coupon ?? null;
  const [couponCode, setCouponCode] = useState(activeCouponCode);
  useEffect(() => {
    setCouponCode(activeCouponCode);
  }, [activeCouponCode]);

  const [pointUsage, setPointUsage] = useState<UsePointType>("none");
  const { isLoggedIn } = useAuth();

  const {
    isSubscription = false,
    lineTotalPrice = 0,
    totalPrice = 0,
    normalProductTotalPrice = 0,
    freezeProductTotalPrice = 0,
    afterSecondTotalPrice = 0,
    normalShippingFee = 0,
    freezeShippingFee = 0,
    totalShippingFee = 0,
    totalPriceCashDeliveryFee = 0,
    couponDiscount = 0,
    pointDiscount = 0,
    isFirstSubscriptionOrder = false,
    hasSubscriptionProduct = false,
    paymentMethod = "ginkou",
    ifChangeSubscriptionFirstTotalPrice = 0,
    ifChangeSubscriptionAfterSecondTotalPrice = 0,
    normalProducts = [],
    freezeProducts = [],
  } = cart ?? {};

  /**
   * vueのcomputedをReactのuseMemoに変換
   */
  const hasNormalProducts = normalProducts.length > 0;
  const hasFreezedProducts = freezeProducts.length > 0;
  const hasBothProducts = hasNormalProducts && hasFreezedProducts;
  // const nextUsePointType = cart.usePointType ?? "none";

  const updateCoupon = useCallback(() => {
    onChangeCoupon(couponCode);
  }, [couponCode, onChangeCoupon]);

  const deleteCoupon = useCallback(() => {
    onChangeCoupon(null);
  }, [onChangeCoupon]);

  const handleEnter = useCallback((el: HTMLElement) => {
    el.style.height = "0";
  }, []);
  const handleEntering = useCallback((el: HTMLElement) => {
    el.style.height = el.scrollHeight + "px";
  }, []);
  const handleExit = useCallback((el: HTMLElement) => {
    el.style.height = el.scrollHeight + "px";
  }, []);
  const handleExiting = useCallback((el: HTMLElement) => {
    el.style.height = "0";
  }, []);

  return (
    <section className={clsx("section", "col-12", "col-m-5")}>
      <CSSTransition
        name="trans-slide"
        timeout={{ enter: 300, exit: 200 }}
        onEnter={handleEnter}
        onEntering={handleEntering}
        onExit={handleExit}
        onExiting={handleExiting}
      >
        <div className={clsx("row", "toggle__cart")}>
          <div className="col-12">
            <div className="row">
              <div className="col-6">
                <p className={clsx("text__m", "text__yellow", "text__bold")}>ご注文内容</p>
              </div>
              <div className="col-6">
                <div className={clsx("text__right", "clear__sp")}>
                  <MiniLinkButton href="/cart">注文内容を変更する</MiniLinkButton>
                </div>
              </div>
            </div>
            <div className={clsx("cart__block", "mg__top__m")}>
              <p className={clsx("text__m", "text__bold", "mg__bottom__m")}>
                {isSubscription ? "継続コース（4週間ごと）" : " 1回のみお届け "}
              </p>
              <div className={styles.mypageBordertop}>
                <div
                  className={clsx(
                    hasBothProducts &&
                      clsx("bg__white mg__top__m mg__bottom__m", styles.cartHasNormalProducts)
                  )}
                >
                  {hasBothProducts && (
                    <p
                      className={clsx(
                        "text__m",
                        "text__bold",
                        "text__white",
                        styles.cartTemperatureTitle
                      )}
                    >
                      常温配送
                    </p>
                  )}
                  <section>
                    {normalProducts.map((product) => {
                      const subTotalPrice = !product.subscription
                        ? product.normalTotalPrice
                        : isFirstSubscriptionOrder
                          ? product.firstSubscriptionTotalPrice
                          : product.afterSecondSubscriptionTotalPrice;
                      return (
                        <OrderItem
                          key={product.variantId}
                          title={product.title}
                          variantTitle={product.variantTitle}
                          showVariantTitle={product.meal !== "組"}
                          imageUrl={product.images[product.variantImageIndex]}
                          quantity={product.quantity}
                          subTotalPrice={subTotalPrice}
                          showInitialDiscount={product.subscription}
                        />
                      );
                    })}
                  </section>
                  {hasBothProducts && (
                    <>
                      <dl
                        className={clsx(
                          styles.totalpriceTable,
                          styles.mypageBordertop,
                          "pd__top__s"
                        )}
                      >
                        <dt className="text__left">
                          <span className="text__m">小計：</span>
                        </dt>
                        <dd className="text__right">
                          <span
                            className={clsx("text__l", "text__bold", isSubscription && "text__red")}
                          >
                            {formatCurrency(normalProductTotalPrice)}
                          </span>
                        </dd>
                      </dl>
                      <dl className={clsx(styles.totalpriceTable, "mg__top__s")}>
                        <dt className="text__left">
                          <span className="text__m">配送料：</span>
                        </dt>
                        <dd className="text__right">
                          <span className={clsx("text__l", "text__bold")}>
                            {formatCurrency(normalShippingFee)}
                            {lang === "ja" && <sup className="sup">**</sup>}
                          </span>
                        </dd>
                      </dl>
                    </>
                  )}
                </div>
                {/* <!-- 冷凍商品 start --> */}
                {hasFreezedProducts && (
                  <div
                    className={clsx(
                      hasBothProducts &&
                        clsx("bg__white mg__top__m mg__bottom__m", styles.cartHasFreezedProducts)
                    )}
                  >
                    {hasBothProducts && (
                      <p
                        className={clsx(
                          "text__m",
                          "text__bold",
                          "text__white",
                          styles.cartTemperatureTitle
                        )}
                      >
                        冷凍配送
                      </p>
                    )}
                    <section>
                      {freezeProducts.map((product) => {
                        const subTotalPrice = !product.subscription
                          ? product.normalTotalPrice
                          : isFirstSubscriptionOrder
                            ? product.firstSubscriptionTotalPrice
                            : product.afterSecondSubscriptionTotalPrice;
                        return (
                          <OrderItem
                            key={product.variantId}
                            title={product.title}
                            variantTitle={product.variantTitle}
                            showVariantTitle={product.meal !== "組"}
                            imageUrl={product.images[product.variantImageIndex]}
                            quantity={product.quantity}
                            subTotalPrice={subTotalPrice}
                            showInitialDiscount={product.subscription}
                          />
                        );
                      })}
                    </section>
                    {hasBothProducts && (
                      <>
                        <dl
                          className={clsx(
                            styles.totalpriceTable,
                            styles.mypageBordertop,
                            "pd__top__s"
                          )}
                        >
                          <dt className="text__left">
                            <span className="text__m">小計：</span>
                          </dt>
                          <dd className="text__right">
                            <span
                              className={clsx(
                                "text__l",
                                "text__bold",
                                isSubscription ? "text__red" : ""
                              )}
                            >
                              {formatCurrency(freezeProductTotalPrice)}
                            </span>
                          </dd>
                        </dl>
                        <dl className={clsx(styles.totalpriceTable, "mg__top__s")}>
                          <dt className="text__left">
                            <span className="text__m">配送料：</span>
                          </dt>
                          <dd className="text__right">
                            <span className={clsx("text__l", "text__bold")}>
                              {formatCurrency(freezeShippingFee)}
                              {lang === "ja" && <sup className="sup">**</sup>}
                            </span>
                          </dd>
                        </dl>
                      </>
                    )}
                  </div>
                )}
              </div>
              {/* <!-- 冷凍商品 end --> */}
              <div className={clsx(styles.mypageBordertop, "pd__bottom__m")}>
                <p className={clsx("text__m", "text__bold", "mg__top__m")}>クーポンコード</p>
                <Row className={clsx(styles.couponContainer, "mg__top__s")}>
                  <Input
                    value={couponCode ?? ""}
                    onChange={(e) => setCouponCode(e.target.value)}
                    type="text"
                    placeholder="クーポン"
                    name="coupon_code_sp"
                  />
                  {activeCouponCode ? (
                    <Button variants="yellow" onClick={deleteCoupon}>
                      削除
                    </Button>
                  ) : (
                    <Button variants="yellow" onClick={updateCoupon}>
                      適用
                    </Button>
                  )}
                </Row>
                {isLoggedIn && currentPoints > 0 && (
                  <div className={clsx(styles.mypageBordertopDashed, "mg__top__m")}>
                    <p className={clsx("text__m", "text__bold", "mg__top__s")}>
                      保有ポイント：
                      <span className={clsx("text__xl", "text__red")}>
                        {numberFormat(currentPoints)}
                      </span>
                      pt
                    </p>
                    <div className="mg__top__m">
                      <RadioButtonGroup
                        name="use_points"
                        value={pointUsage}
                        onChange={(newType) => {
                          setPointUsage(newType);
                          onChangePoint(newType === "all" ? currentPoints : 0);
                        }}
                        options={pointOptions}
                      />
                    </div>
                  </div>
                )}
              </div>
              <dl
                className={clsx(styles.mypageBordertop, "table__dl", "pd__bottom__s", "pd__top__m")}
              >
                <dt>
                  <BasicParagraph>小計：</BasicParagraph>
                </dt>
                <dd className="text__right">
                  <span
                    className={clsx(
                      totalShippingFee > 0 ? "text__l text__bold" : "text__l text__bold text__red"
                    )}
                  >
                    {formatCurrency(lineTotalPrice)}
                  </span>
                </dd>
              </dl>
              <dl className={clsx("table__dl", "pd__bottom__s")}>
                <dt>
                  <BasicParagraph>配送料：</BasicParagraph>
                </dt>
                <dd className="text__right">
                  <span
                    className={
                      totalShippingFee > 0 ? "text__l text__bold" : "text__l text__bold text__red"
                    }
                  >
                    {formatCurrency(totalShippingFee)}
                    {lang === "ja" && <sup className="sup">**</sup>}
                  </span>
                </dd>
              </dl>
              {totalPriceCashDeliveryFee > 0 && (
                <dl className={clsx("table__dl", "pd__bottom__s")}>
                  <dt>
                    <BasicParagraph>代引き手数料：</BasicParagraph>
                  </dt>
                  <dd className="text__right">
                    <span className={clsx("text__l", "text__bold")}>
                      {formatCurrency(totalPriceCashDeliveryFee)}
                      <sup className="sup">**</sup>
                    </span>
                  </dd>
                </dl>
              )}
              {couponDiscount > 0 && (
                <dl className={clsx("table__dl", "pd__bottom__s")}>
                  <dt>
                    <BasicParagraph>クーポン値引き：</BasicParagraph>
                  </dt>
                  <dd className="text__right">
                    <span className={clsx("text__l", "text__bold", "text__red")}>
                      - {formatCurrency(couponDiscount)}
                    </span>
                  </dd>
                </dl>
              )}
              {pointDiscount > 0 && (
                <dl className={clsx("table__dl", "pd__bottom__s")}>
                  <dt>
                    <BasicParagraph>ポイント値引き：</BasicParagraph>
                  </dt>
                  <dd className="text__right">
                    <span className={clsx("text__l", "text__bold", "text__red")}>
                      - {formatCurrency(pointDiscount)}
                    </span>
                  </dd>
                </dl>
              )}
              <dl className={clsx("table__dl", "pd__top__m", "border__top")}>
                <dt>
                  <BasicParagraph>合計：</BasicParagraph>
                </dt>
                <dd className={clsx("text__right", isSubscription && "text__red")}>
                  {isSubscription && isFirstSubscriptionOrder && (
                    <>
                      <span className={clsx("text__s", styles.firsttime)}>継続コース初回価格</span>
                      <br />
                    </>
                  )}
                  <span className={clsx("text__xxl", "text__bold")}>
                    {formatCurrency(Number(totalPrice) + Number(totalPriceCashDeliveryFee))}
                  </span>
                </dd>
              </dl>
              <div className="text__right">
                {isSubscription && (
                  <p className={clsx("text__s", "mg__bottom__s")} style={{ marginTop: "-4px" }}>
                    ２回目以降のご購入から
                    <span className={clsx("text__m", "text__bold")}>
                      {formatCurrency(afterSecondTotalPrice)}
                    </span>
                  </p>
                )}
                <p className={clsx("text__s", "text__gray__dark")}>
                  ※価格はすべて税込表示 *税率8% **税率10%
                </p>
                {!isSubscription && !isLoggedIn && hasSubscriptionProduct && (
                  <p className={clsx("text__red", "text__s")}>
                    ※継続コースにすると初回
                    {formatCurrency(totalPrice - ifChangeSubscriptionFirstTotalPrice)}
                    、以降
                    {formatCurrency(totalPrice - ifChangeSubscriptionAfterSecondTotalPrice)}
                    お得 *
                  </p>
                )}

                {paymentMethod === "ginkou" && (
                  <p className={clsx("text__s", "text__gray__dark")}>
                    ※銀行振込（銀行指定の金額）手数料別
                  </p>
                )}
                {paymentMethod === "konbini" && (
                  <p className={clsx("text__s", "text__gray__dark")}>
                    ※コンビニ支払い手数料（¥190）別
                  </p>
                )}
              </div>
              <div className={clsx("mg__top__l", "text__center", "clear__pc", styles.clearPc)}>
                <Link
                  href="/cart"
                  className={clsx(
                    "btn",
                    styles.btn,
                    styles.inline,
                    "round",
                    "angle__left",
                    "yellow"
                  )}
                >
                  <i className={clsx("fas", "fa-angle-left")} />
                  注文内容を変更する
                </Link>
              </div>
              <div
                className={clsx(
                  "mg__top__m",
                  "mg__bottom__l",
                  "clear__pc",
                  styles.clearPc,
                  "text__center"
                )}
              >
                <img
                  src="https://asset.basefood.co.jp/images/parts/divider.png"
                  width="50"
                  height="10"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
      {hasBothProducts && (
        <CSSTransition
          name="trans-slide"
          timeout={{ enter: 300, exit: 200 }}
          onEnter={handleEnter}
          onEntering={handleEntering}
          onExit={handleExit}
          onExiting={handleExiting}
        >
          <div
            className={clsx(
              styles.mypageSubscriptionProducts,
              "mg__top__s",
              "mg__bottom__m",
              "clear__pc",
              styles.clearPc
            )}
          >
            <ul>
              {normalProducts?.map((product) => {
                const key = product.variantId + (product.subscription === true ? "1" : "2");
                return (
                  <li key={key}>
                    <picture>
                      <source type="image/webp" srcSet={convertToWebpUrl(product.images[0])} />
                      <img src={product.images[0]} alt={product.variantTitle} />
                    </picture>
                    <span className={clsx("text__s", "text__bold", "text__white", "text__center")}>
                      {product.quantity}
                    </span>
                  </li>
                );
              })}
              {freezeProducts?.map((product) => {
                const key = product.variantId + (product.subscription === true ? "1" : "2");
                return (
                  <li key={key}>
                    <picture>
                      <source type="image/webp" srcSet={convertToWebpUrl(product.images[0])} />
                      <img src={product.images[0]} alt={product.variantTitle} />
                    </picture>
                    <span className={clsx("text__s", "text__bold", "text__white", "text__center")}>
                      {product.quantity}
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>
        </CSSTransition>
      )}
    </section>
  );
}
