import { useMemo } from "react";

import { QueryObserverOptions, QueryOptions } from "@tanstack/react-query";

import { useClientFrontStoreCartItems } from "@/storage";

import { useGetParsedCart, useGetParsedCartSuspense } from "./useGetParsedCart";

export type UseGetParsedCartFromCartItemsOptions = Omit<QueryOptions, "queryKey" | "queryFn"> &
  Omit<QueryObserverOptions, "queryKey" | "queryFn">;

/**
 * @remarks
 * Client Side renderingでのみ対応
 */
export function useGetParsedCartFromCartItems(options?: UseGetParsedCartFromCartItemsOptions) {
  const { cartItems } = useClientFrontStoreCartItems();
  const params = useMemo(
    () => ({
      products: cartItems.map((item) => ({
        variantId: item.variantId.toString(),
        quantity: item.quantity.toString(),
        subscription: item.subscription,
      })),
    }),
    [cartItems]
  );
  return useGetParsedCart(params, options);
}

export function useGetParsedCartFromCartItemsSuspense(
  options?: UseGetParsedCartFromCartItemsOptions
) {
  const { cartItems } = useClientFrontStoreCartItems();
  const params = useMemo(
    () => ({
      products: cartItems.map((item) => ({
        variantId: item.variantId.toString(),
        quantity: item.quantity.toString(),
        subscription: item.subscription,
      })),
    }),
    [cartItems]
  );
  return useGetParsedCartSuspense(params, options);
}
