import React from "react";

import clsx from "clsx";

import { Image } from "@/components/displays";

import styles from "./Privilege.module.scss";

interface PrivilegeProps {
  font?: "gothic" | "maru";
}

export function Privilege({ font = "gothic" }: PrivilegeProps): React.ReactNode {
  const isGothic = font === "gothic";
  const pngSrc = isGothic
    ? "https://asset.basefood.co.jp/lp/common/privilege_gothic_0805.png"
    : "https://asset.basefood.co.jp/lp/common/privilege_maru_0805.png";

  return (
    <Image
      src={pngSrc}
      alt="はじめての方限定 2つの初回特典"
      aspectRatio="375 / 461"
      containerClassName={clsx(styles.image)}
    />
  );
}
