import { AxiosError, isAxiosError } from "axios";

export class HandleError extends Error {}

export class ValidationError extends HandleError {}

const isHandleError = (error: unknown): error is HandleError => {
  return error instanceof HandleError;
};

// ユーザー画面表示用のエラーメッセージを取得する。
// 表に出せない情報は表示しないように注意する。
export const getErrorMessages = (error: unknown): string[] => {
  if (isHandleError(error)) {
    return [error.message === "" ? "不明なエラーが発生しました" : error.message];
  }
  if (isAxiosError(error)) {
    return getAxiosErrorMessages(error);
  }
  return ["不明なエラーが発生しました"];
};

// バックエンドが定義したスキーマのエラーメッセージがある場合のみmessagesを更新する
const getAxiosErrorMessages = (error: AxiosError): string[] => {
  const data = error.response?.data;
  if (!data) {
    return ["不明なエラーが発生しました"];
  }
  if (typeof data === "string") {
    return [data];
  }
  if (typeof data === "object" && data !== null) {
    if ("messages" in data) {
      return data.messages as string[];
    }

    if ("errors" in data) {
      return Object.values(data.errors as Record<string, string>);
    }

    if ("exception" in data) {
      const exception = data.exception;
      if (typeof exception === "string") {
        return [exception];
      }
      if (Array.isArray(exception)) {
        return exception as string[];
      }
      if (typeof exception === "object") {
        return Object.values(exception as Record<string, string>);
      }
    }
  }

  return ["不明なエラーが発生しました"];
};
