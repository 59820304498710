import { useMemo } from "react";

import { QueryClient, useSuspenseQuery } from "@tanstack/react-query";

import { amazonGetCheckout } from "@/generated/axios-functions/payseAPI";
import { AmazonGetCheckout200, AmazonGetCheckout200OneOf } from "@/generated/open-api/schemas";
import { convertAmazonGetCheckoutResToAmazonPayGetCheckoutData } from "@/models/amazonpay/converter";

interface AmazonPayCheckoutParams {
  amazonCheckoutSessionId: string | null;
}

/**
 * HTTPメソッドがPOSTでSuspenseのhooksが生成されないため自作する
 */
export function useAmazonGetCheckoutSuspense({ amazonCheckoutSessionId }: AmazonPayCheckoutParams) {
  const { data, ...rest } = useSuspenseQuery({
    queryKey: getAmazonGetCheckoutQueryKey(amazonCheckoutSessionId),
    queryFn: async () => {
      if (!amazonCheckoutSessionId) {
        return null;
      }
      return await amazonGetCheckout({ amazonCheckoutSessionId });
    },
  });
  const amazonPayGetCheckoutData = useMemo(() => {
    return isAmazonGetCheckoutSuccessResponse(data)
      ? convertAmazonGetCheckoutResToAmazonPayGetCheckoutData(data)
      : undefined;
  }, [data]);
  return { data: amazonPayGetCheckoutData, ...rest };
}

type PrefetchAmazonPayCheckoutParams = {
  queryClient: QueryClient;
} & AmazonPayCheckoutParams &
  Parameters<typeof amazonGetCheckout>[1];

export async function prefetchAmazonGetCheckout(params: PrefetchAmazonPayCheckoutParams) {
  const { queryClient, amazonCheckoutSessionId, ...rest } = params;
  await queryClient.prefetchQuery({
    queryKey: getAmazonGetCheckoutQueryKey(amazonCheckoutSessionId),
    queryFn: async () => {
      if (!amazonCheckoutSessionId) {
        return null;
      }
      return await amazonGetCheckout({ amazonCheckoutSessionId }, rest);
    },
  });
}

export function getAmazonGetCheckoutQueryKey(amazonCheckoutSessionId: string | null) {
  return [`/amazon/get_checkout`, amazonCheckoutSessionId];
}

function isAmazonGetCheckoutSuccessResponse(
  data: AmazonGetCheckout200 | null
): data is AmazonGetCheckout200OneOf {
  return (
    data !== null &&
    "email" in data &&
    "name" in data &&
    "payment_preferences" in data &&
    "shipping_address" in data
  );
}
