import clsx from "clsx";

import { Image } from "@/components";

import styles from "./LineupSectionItem.module.scss";

export interface LineupSectionItemProps {
  title: string;
  subTitle: string;
  imageUrl: string;
  description: string;
  quantity: string;
}

export function LineupSectionItem({
  title,
  subTitle,
  imageUrl,
  description,
  quantity,
}: LineupSectionItemProps): React.ReactNode {
  return (
    <>
      <div className="text__center mg__bottom__m">
        <p className="text__white text__bold text__l">{subTitle}</p>
        <p className="text__bold text__l">
          BASE BREAD
          <sup className="sup">
            <i className="far fa-registered fa-fw"></i>
          </sup>
          {title}
        </p>
      </div>

      <Image src={imageUrl} alt={title} />

      <div className="mg__top__m mg__bottom__m text__center">
        <span
          className={clsx(
            "btn tag inline radius text__black text__m mg__bottom__s bg__white round",
            styles.tag
          )}
        >
          {description}
        </span>
        <span
          className={clsx(
            "btn tag inline radius text__black text__m mg__bottom__s bg__white round",
            styles.tag
          )}
        >
          {quantity}
        </span>
      </div>
    </>
  );
}
