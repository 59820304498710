"use client";
import { useCallback } from "react";

import { StripeCardNumberElement } from "@stripe/stripe-js";
import clsx from "clsx";
import { Tooltip } from "react-tooltip";

import { Column, Row } from "@/components/containers";
import { Image } from "@/components/displays";
import { PanelSelector, RadioButtonGroup } from "@/components/inputs";
import { ConvenienceStore, PaymentMethod } from "@/models/payment/consts";
import { nl2br } from "@/utils/jsx";
import { loadStripe } from "@/utils/stripe";

import { convenienceStoreOptions } from "./constants";
import styles from "./PaymentMethodSelector.module.scss";
import { BankTransferForm } from "../BankTransferForm";
import { CreditCardForm, CreditCardFormProps } from "../CreditCardForm";

export interface PaymentData {
  paymentMethod?: PaymentMethod;
  store?: ConvenienceStore; // コンビニ支払いの場合に選択される店舗
  bankFirstName?: string; // 銀行振込の場合の姓
  bankLastName?: string; // 銀行振込の場合の名
  bankLastNameKana?: string; // 銀行振込の場合のセイ
  bankFirstNameKana?: string; // 銀行振込の場合のメイ

  // ここから下はStripeの情報
  creditCard?: StripeCardNumberElement;
  stripeToken?: string;
  last4?: string;
}

interface PaymentMethodSelectorProps {
  paymentData?: PaymentData;
  onChangePaymentData: (paymentData: PaymentData) => void;
  showConvenience?: boolean;
  showBankTransfer?: boolean;
  showDaibiki?: boolean;
  daibikiDisabled?: boolean;
}

const defaultPaymentData: PaymentData = {};

export function PaymentMethodSelector({
  paymentData = defaultPaymentData,
  onChangePaymentData,
  showConvenience = false,
  showBankTransfer = false,
  showDaibiki = false,
  daibikiDisabled,
}: PaymentMethodSelectorProps): React.ReactNode {
  const handleChangeCreditCard = useCallback<CreditCardFormProps["onChange"]>(
    async ({ cardNumber }, event) => {
      if (event.complete) {
        const stripe = await loadStripe();
        const cardRes = await stripe.createToken(cardNumber);
        onChangePaymentData({
          ...paymentData,
          creditCard: cardNumber,
          stripeToken: cardRes.token?.id,
          last4: cardRes.token?.card?.last4,
        });
      } else {
        onChangePaymentData({ ...paymentData, creditCard: cardNumber });
      }
    },
    [onChangePaymentData, paymentData]
  );

  return (
    <PanelSelector
      value={paymentData.paymentMethod}
      onChange={(paymentMethod) => onChangePaymentData({ ...paymentData, paymentMethod })}
      options={[
        {
          title: (
            <Row className={styles.creditCardTitleContainer}>
              <p>クレジットカード支払い</p>
              <Image
                src="https://asset.basefood.co.jp/images/parts/card_brands.png"
                alt="クレジットカード"
                size={{ width: 167, height: 24 }}
              />
            </Row>
          ),
          value: PaymentMethod.credit,
          content: (
            <Column className={styles.creditCardFormContainer}>
              <CreditCardForm onChange={handleChangeCreditCard} />
            </Column>
          ),
        },
        showConvenience && {
          title: (
            <Row className={styles.creditCardTitleContainer}>
              <p>
                コンビニ支払い（手数料190円）
                <span className="wsnr"></span>
              </p>
              <Image
                src="/assets/images/convenience-store-pay.png"
                alt="コンビニ支払い"
                className="payment__image"
                size={{ width: 167, height: 24 }}
              />
            </Row>
          ),
          value: PaymentMethod.konbini,
          content: (
            <Column className={styles.konbiniContainer}>
              <RadioButtonGroup
                name="store"
                options={convenienceStoreOptions}
                onChange={(store) => onChangePaymentData({ ...paymentData, store })}
              />
            </Column>
          ),
        },
        showBankTransfer && {
          title: (
            <Row>
              銀行振込
              <i
                id="bank-transfer-fee-tooltip"
                className={clsx("fas fa-question-circle text__m", styles.questionIcon)}
              />
              <Tooltip anchorSelect="#bank-transfer-fee-tooltip">
                銀行指定の振込手数料はお客さま負担となります
              </Tooltip>
            </Row>
          ),
          value: PaymentMethod.ginkou,
          content: (
            <Column className={styles.bankTransferFormContainer}>
              <BankTransferForm
                onChange={(formValues) => {
                  const { lastName, firstName, lastNameKana, firstNameKana } = formValues;
                  onChangePaymentData({
                    ...paymentData,
                    bankFirstName: lastName,
                    bankLastName: firstName,
                    bankLastNameKana: lastNameKana,
                    bankFirstNameKana: firstNameKana,
                  });
                }}
              />
            </Column>
          ),
        },
        {
          title: "Amazon Pay",
          value: PaymentMethod.amazon,
        },
        showDaibiki && {
          title: (
            <Row>
              代金引換（手数料330円〜
              <i
                id="shipping-fee-tooltip"
                className={clsx("fas fa-question-circle text__m", styles.questionIcon)}
              />
              <Tooltip anchorSelect="#shipping-fee-tooltip">
                {nl2br(`9,670円未満のご購入：手数料330円
                        9,670円～29,559円のご購入：手数料440円
                        29,560~54,339円のご購入：手数料660円
                        54,340円以上のご購入：手数料880円
                        ※配送費含む`)}
              </Tooltip>
              ）
            </Row>
          ),
          value: PaymentMethod.daibiki,
          disabled: daibikiDisabled,
        },
      ]}
      disabledReason={
        daibikiDisabled ? "受取方法が置き配のため代金引換が選択できません" : undefined
      }
    />
  );
}
