import { useCallback, useMemo } from "react";

import {
  QueryObserverOptions,
  QueryOptions,
  useQuery,
  useSuspenseQuery,
} from "@tanstack/react-query";

import { postCart } from "@/generated/axios-functions/payseAPI";
import { PostCartBody } from "@/generated/open-api/schemas";
import { convertApiCartToCart } from "@/models/cart/converters";
import { CartModel } from "@/models/cart/type";
import { SnakeToCamelCaseNested } from "@/utils";
import { convertObjToSnakeCase } from "@/utils/converters";
import { objectKeys } from "@/utils/object";

export type GetParsedCartParams = SnakeToCamelCaseNested<PostCartBody>;

export async function getParsedCart(params: GetParsedCartParams) {
  const res = (await postCart(convertObjToSnakeCase(params))).cart;
  if (!res) return undefined;
  return convertApiCartToCart(res);
}

export type UseGetParsedCartOptions = Omit<QueryOptions, "queryKey" | "queryFn"> &
  Omit<QueryObserverOptions, "queryKey" | "queryFn">;
/**
 * HTTPメソッドがPOSTでSuspenseのhooksが生成されないため自作する
 */
export function useGetParsedCart(params: GetParsedCartParams, options?: UseGetParsedCartOptions) {
  const queryKey = useMemo(() => getParsedCartKey(params), [params]);
  const queryFn = useCallback(() => getParsedCart(params), [params]);
  const { data, ...rest } = useQuery({ queryKey, queryFn, ...options });
  const cart = useMemo(() => data, [data]);
  return { data: cart as CartModel | undefined, ...rest };
}

export function useGetParsedCartSuspense(
  params: GetParsedCartParams,
  options?: UseGetParsedCartOptions
) {
  const queryKey = useMemo(() => getParsedCartKey(params), [params]);
  const queryFn = useCallback(() => getParsedCart(params), [params]);
  const { data, ...rest } = useSuspenseQuery({ queryKey, queryFn, ...options });
  const cart = useMemo(() => data, [data]);
  return { data: cart as CartModel | undefined, ...rest };
}

export function getParsedCartKey(params: GetParsedCartParams) {
  const sortedKeys = objectKeys(params).sort();
  // ソートされたキーを使用して新しいURLSearchParamsを生成
  const sortedParams = new URLSearchParams();
  sortedKeys.forEach((key) => {
    sortedParams.append(key, JSON.stringify(params[key]));
  });
  return [`/cart`, sortedParams.toString()];
}
