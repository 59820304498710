"use client";

import React, { useCallback } from "react";

import clsx from "clsx";

import { Image, Scroll } from "@/components/displays";

import styles from "./CvButton.module.scss";
import { useLPStore } from "../store";

interface CvButtonProps {
  bags: number;
  width?: "narrow";
  font?: "gothic" | "maru";
}

export function CvButton({ bags, width, font = "gothic" }: CvButtonProps): JSX.Element {
  const { setSelectIndex } = useLPStore();
  const getImage = () => {
    const basePath = "https://asset.basefood.co.jp/lp/common";
    const imageName = font === "gothic" ? `btn_set${bags}_gothic` : `btn_set${bags}_maru`;
    const pngSrc = `${basePath}/${imageName}.png`;
    const altText = `${bags}食セットを購入`;

    return { pngSrc, altText };
  };

  const { pngSrc, altText } = getImage();
  const additionalClass = width === "narrow" ? styles.cvButton : "";

  const handlesetSelectIndex = useCallback(() => {
    // TODO: LP_DIET_02と紐づけする
    let index = 1;
    switch (bags) {
      case 14:
        index = 0;
        break;
      case 54:
        index = 2;
        break;
    }

    setSelectIndex(index);
  }, [bags, setSelectIndex]);

  return (
    <Scroll to="lp-form" onClick={handlesetSelectIndex}>
      <Image
        src={pngSrc}
        alt={altText}
        aspectRatio="610 / 131"
        containerClassName={clsx(styles.imageDeleteMargin, "full__image", additionalClass)}
      />
    </Scroll>
  );
}
