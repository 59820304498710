"use client";
import { useMemo, useState } from "react";

import clsx from "clsx";

import { Column } from "@/components/containers";
import { PanelSelector, Selector, SelectorOption } from "@/components/inputs";
import { getCartValidDates } from "@/generated/axios-functions/payseAPI";
import { DeliveryReceiveOption } from "@/models/delivery/consts";
import { useAsync } from "@/utils/hooks";
import { getDomesticProvince } from "@/utils/province";

import { deliveryReceiveOptions, deliveryTimeZoneOptions } from "./consts";
import { DeliveryCautions } from "./DeliveryCautions";
import styles from "./DeliveryDateSelector.module.scss";
import { DeliveryOptionsValues } from "../CheckoutForm/schema";

interface DeliveryDateSelectorProps {
  onChangeDeliveryOptions: (option: DeliveryOptionsValues) => void;
  deliveryOptions: Partial<DeliveryOptionsValues>;
  displayDeliveryTypeLabel?: boolean;
  province?: string; // 置き配に対応しているかどうかに利用
  isFreezeDelivery?: boolean;
  isNormalDelivery?: boolean;
  isSubscription?: boolean;
  disabledReceiveOption?: boolean;
  disabledReceiveOptionReason?: string;
}

const dateToStr = (date: Date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}/${month}/${day}`;
};

const strToDate = (str: string) => {
  const [year, month, date] = str.split("/").map((v) => parseInt(v, 10));
  return new Date(year, month - 1, date);
};

export function DeliveryDateSelector({
  onChangeDeliveryOptions,
  deliveryOptions,
  displayDeliveryTypeLabel = true,
  province,
  isFreezeDelivery = false,
  isNormalDelivery = false,
  isSubscription = false,
  disabledReceiveOption = false,
  disabledReceiveOptionReason,
}: DeliveryDateSelectorProps): React.ReactNode {
  const [dateOptions, setDateOptions] = useState<SelectorOption<string>[]>([]);
  const { isSpecifiedDate } = deliveryOptions;

  const supportDropOff = useMemo(() => {
    const provinceObj = province ? getDomesticProvince(province) : undefined;
    return provinceObj?.supportDropOff ?? false;
  }, [province]);

  const defaultDeliveryOptions = useMemo(() => {
    return {
      isSpecifiedDate: false,
      deliveryDate: dateOptions[0] ? strToDate(dateOptions[0].value) : new Date(),
      deliveryTimezone: "am",
      deliveryReceiveOption: DeliveryReceiveOption.FaceToFace,
    } as const;
  }, [dateOptions]);

  useAsync(async () => {
    const { valid_dates } = await getCartValidDates();
    const options =
      valid_dates?.map((validDate) => {
        // valueにはyyyy/MM/ddの形式で値が入っている
        return {
          value: validDate.value!,
          label: validDate.label!,
        };
      }) ?? [];
    setDateOptions(options);
  }, []);

  return (
    <>
      <PanelSelector
        value={isSpecifiedDate}
        onChange={(value) =>
          onChangeDeliveryOptions({
            ...defaultDeliveryOptions,
            ...deliveryOptions,
            isSpecifiedDate: value,
          })
        }
        options={[
          {
            title: "日付を指定せずにお届け",
            value: false,
            content: (
              <Column className={styles.itemContainer}>
                {displayDeliveryTypeLabel && (
                  <p className={styles.itemRow}>
                    {isFreezeDelivery ? (
                      <span className="tag inline darkblue text__white mini square">冷凍配送</span>
                    ) : (
                      <span className="tag inline yellow text__white mini square">常温配送</span>
                    )}
                  </p>
                )}
                <p className={clsx("text__m", styles.itemRow)}>受取方法：</p>
                <Selector
                  className={styles.selector}
                  options={deliveryReceiveOptions}
                  value={deliveryOptions.deliveryReceiveOption}
                  disabled={disabledReceiveOption}
                  onChange={(value) =>
                    onChangeDeliveryOptions({
                      ...defaultDeliveryOptions,
                      ...deliveryOptions,
                      deliveryReceiveOption: value,
                    })
                  }
                />
                <div className="mg__top__s">
                  {disabledReceiveOption && (
                    <p className="text__s text__gray__dark">{disabledReceiveOptionReason}</p>
                  )}
                </div>
              </Column>
            ),
          },
          {
            title: "日付を指定してお届け",
            value: true,
            content: (
              <Column className={styles.itemContainer}>
                {displayDeliveryTypeLabel && (
                  <p className={styles.itemRow}>
                    {isFreezeDelivery ? (
                      <span className="tag inline darkblue text__white mini square">冷凍配送</span>
                    ) : (
                      <span className="tag inline yellow text__white mini square">常温配送</span>
                    )}
                  </p>
                )}
                <div className={styles.dateSelectorsContainer}>
                  <Selector
                    className={styles.selector}
                    options={dateOptions}
                    value={
                      deliveryOptions.deliveryDate ? dateToStr(deliveryOptions.deliveryDate) : ""
                    }
                    onChange={(value) =>
                      onChangeDeliveryOptions({
                        ...defaultDeliveryOptions,
                        ...deliveryOptions,
                        deliveryDate: strToDate(value),
                      })
                    }
                  />
                  <Selector
                    className={styles.selector}
                    options={deliveryTimeZoneOptions}
                    value={deliveryOptions.deliveryTimezone}
                    onChange={(value) =>
                      onChangeDeliveryOptions({
                        ...defaultDeliveryOptions,
                        ...deliveryOptions,
                        deliveryTimezone: value,
                      })
                    }
                  />
                </div>
                <p className={clsx("text__m", styles.itemRow)}>受取方法：</p>
                <Selector
                  className={styles.selector}
                  options={deliveryReceiveOptions}
                  value={deliveryOptions.deliveryReceiveOption}
                  onChange={(value) =>
                    onChangeDeliveryOptions({
                      ...defaultDeliveryOptions,
                      ...deliveryOptions,
                      deliveryReceiveOption: value,
                    })
                  }
                  disabled={disabledReceiveOption}
                />
                <div className="mg__top__s">
                  {disabledReceiveOption && (
                    <p className="text__s text__gray__dark">{disabledReceiveOptionReason}</p>
                  )}
                </div>
              </Column>
            ),
          },
        ]}
      />
      <DeliveryCautions
        supportDropOff={supportDropOff}
        isFreezeDelivery={isFreezeDelivery}
        isNormalDelivery={isNormalDelivery}
        isSubscription={isSubscription}
      />
    </>
  );
}
