"use client";

import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import { LineupSectionItem } from "@/components/domains/lp/LineupSection/LineupSectionItem";

import { items } from "./consts";
import styles from "./LineupSection.module.scss";

import "swiper/css";
import "swiper/css/pagination";
import "../../../template/lp/Form01/styles/swipper.scss";

interface LineupSectionProps {}

export function LineupSection({}: LineupSectionProps): React.ReactNode {
  return (
    <section>
      <div className="bg__yellow pd__top__xl pd__bottom__xl clear__sp">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="text__center mg__bottom__l">
                <h3 className="text__xl text__bold">8種類のラインナップ</h3>
              </div>
            </div>
          </div>
          <div className="row">
            {items.map((item) => (
              <div className="col-12 col-m-4 pd__top__l" key={item.title}>
                <LineupSectionItem key={item.title} {...item} />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="bg__yellow pd__top__xl clear__pc">
        <div className="container">
          <div className="row">
            <div className="col-12 col-m-8 col-offset-m-2">
              <div className="text__center mg__bottom__l">
                <h3 className="text__xl text__bold">8種類のラインナップ</h3>
              </div>
            </div>
          </div>
        </div>
        <Swiper
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
          className={styles.swiper}
          spaceBetween={20}
          slidesPerView={1}
        >
          {items.map((item, index) => (
            <SwiperSlide key={index}>
              <LineupSectionItem key={index} {...item} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
}
