"use client";
import { useEffect } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import clsx from "clsx";
import { Controller, FormProvider, useForm } from "react-hook-form";

import { Column } from "@/components/containers";

import styles from "./BankTransferForm.module.scss";
import { BankTransferFormSchema, BankTransferFormValues } from "./schema";
import { FieldLabel } from "../FieldLabel";

interface ChangeEvent {
  name?: keyof BankTransferFormValues;
  type?: string;
  value: unknown;
}
export interface BankTransferFormProps {
  formId?: string;
  defaultValues?: Partial<BankTransferFormValues>;
  onChange?: (values: Partial<BankTransferFormValues>, event: ChangeEvent) => void;
}

export function BankTransferForm({
  formId,
  defaultValues,
  onChange,
}: BankTransferFormProps): React.ReactNode {
  const formContext = useForm<BankTransferFormValues>({
    resolver: zodResolver(BankTransferFormSchema),
    defaultValues,
  });

  const { watch, getValues } = formContext;

  // フォームの値の変更を監視
  useEffect(() => {
    if (!onChange) return;
    const subscription = watch((value, { name, type }) => {
      if (type === undefined && name === undefined) {
        // typeとnameが両方undefinedの場合は、初期値の設定時に発火するため、無視する
        return;
      }
      const formValues = getValues();
      onChange(formValues as BankTransferFormValues, {
        name: name as keyof BankTransferFormValues,
        type,
        value,
      });
    });

    // コンポーネントのアンマウント時に購読を解除
    return () => subscription.unsubscribe();
  }, [watch, onChange, formContext, getValues]);

  return (
    <form id={formId}>
      <FormProvider {...formContext}>
        <div className={styles.fieldGroup}>
          <Controller
            name="lastName"
            render={({ field: { value, ...field } }) => (
              <Column className={styles.fieldItem}>
                <FieldLabel>姓</FieldLabel>
                <input
                  {...field}
                  value={value}
                  className={clsx("text__m", styles.field)}
                  type="text"
                  placeholder="姓"
                />
              </Column>
            )}
          />
          <Controller
            name="firstName"
            render={({ field: { value, ...field } }) => (
              <Column className={styles.fieldItem}>
                <FieldLabel>名</FieldLabel>
                <input
                  {...field}
                  value={value}
                  className={clsx("text__m", styles.field)}
                  type="text"
                  placeholder="名"
                />
              </Column>
            )}
          />
        </div>
        <div className={styles.fieldGroup}>
          <Controller
            name="lastNameKana"
            render={({ field: { value, ...field } }) => (
              <Column className={styles.fieldItem}>
                <FieldLabel>セイ</FieldLabel>
                <input
                  {...field}
                  value={value}
                  className={clsx("text__m", styles.field)}
                  type="text"
                  placeholder="セイ"
                />
              </Column>
            )}
          />
          <Controller
            name="firstNameKana"
            render={({ field: { value, ...field } }) => (
              <Column className={styles.fieldItem}>
                <FieldLabel>メイ</FieldLabel>
                <input
                  {...field}
                  value={value}
                  className={clsx("text__m", styles.field)}
                  type="text"
                  placeholder="メイ"
                />
              </Column>
            )}
          />
        </div>
      </FormProvider>
    </form>
  );
}
