"use client";

import { type PropsWithChildren } from "react";

import clsx from "clsx";

import { Column, Row } from "@/components/containers";
import { Image } from "@/components/displays";
import { useDisableBodyScroll } from "@/utils/hooks";

import styles from "./Modal.module.scss";

export interface ModalProps {
  className?: string;
  footerClassName?: string;
  bodyClassName?: string;
  open: boolean;
  closeModal: () => void;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  displayCloseButton?: boolean;
}

export function Modal({
  className,
  footerClassName,
  bodyClassName,
  open,
  closeModal,
  header,
  footer,
  displayCloseButton = true,
  children,
}: PropsWithChildren<ModalProps>): React.ReactNode {
  useDisableBodyScroll(open);

  return (
    <div className={styles.root}>
      <div className={clsx(styles.modal, { [styles.modalActive]: open })}>
        <div className={styles.modalMask}>
          <div className={styles.modalWrapper}>
            <Column className={clsx(styles.modalContainer, className)}>
              {displayCloseButton && (
                <Image
                  containerClassName={styles.modalClose}
                  src="/assets/images/close.svg"
                  alt="閉じる"
                  size={{ width: 40, height: 40 }}
                  onClick={closeModal}
                />
              )}
              <Row className={styles.modalHeader}>{header}</Row>
              <div className={clsx(styles.modalBody, bodyClassName)}>{children}</div>
              {footer && <div className={clsx(styles.modalFooter, footerClassName)}>{footer}</div>}
            </Column>
          </div>
        </div>
      </div>
    </div>
  );
}
