import { useMemo } from "react";

import { useGetSubscriptionSuspense } from "@/generated/open-api/subscription/subscription";
import { convertApiSubscriptionToSubscription } from "@/models/subscription/converters";

export const useParsedGetSubscription = () => {
  const { data, ...rest } = useGetSubscriptionSuspense();
  const parsed = useMemo(() => convertApiSubscriptionToSubscription(data), [data]);
  return { data: parsed, ...rest };
};
