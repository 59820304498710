import { type RecommendSet } from "./types";

export const COOKIES_01: RecommendSet[] = [
  {
    products: [
      { sku: "104006", quantity: 4 },
      { sku: "104003", quantity: 2 },
      { sku: "104004", quantity: 2 },
      { sku: "104005", quantity: 2 },
      { sku: "104007", quantity: 2 },
    ],
    subscription: true,
  },
];
