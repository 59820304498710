import { ProgressBar } from "@/components";
import { formatCurrency } from "@/utils/currency";

import styles from "./PriceProgressBar.module.scss";

export interface PriceProgressBarProps {
  price: number;
  max: number;
  title: string;
  colorClass: string;
}

export function PriceProgressBar({
  price,
  max,
  title,
  colorClass,
}: PriceProgressBarProps): React.ReactNode {
  return (
    <div className="mg__top__xs">
      <ProgressBar num={price} max={max} className={colorClass}>
        <div className={styles.children}>
          <span className="text__s">{title}</span>
          {price < max ? (
            <span data-v-e2a39a58="" className="text__s">
              購入するにはあと
              <b data-v-e2a39a58="" className="text__bold">
                {formatCurrency(price)}
              </b>
            </span>
          ) : (
            <span className="text__s">購入できます</span>
          )}
        </div>
      </ProgressBar>
    </div>
  );
}
