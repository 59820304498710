import { type RecommendSet } from "./types";

export const LP_FORM_01: RecommendSet[] = [
  {
    name: "まずはおためし 14袋セット",
    products: [
      { sku: "102008", quantity: 4 },
      { sku: "102009", quantity: 2 },
      { sku: "102010", quantity: 2 },
      { sku: "102011", quantity: 2 },
      { sku: "102013", quantity: 2 },
      { sku: "102014", quantity: 2 },
    ],
    is_best_value: false,
    subscription: true,
    images: [
      {
        src: "https://asset.basefood.co.jp/lp/common/lp_set_content_14_0626.png",
        aspectRatio: "164/135",
      },
    ],
    sub_title: "まずはおためし",
  },
  {
    name: "からだを変えたいなら 32袋セット",
    products: [
      { sku: "102008", quantity: 6 },
      { sku: "102009", quantity: 6 },
      { sku: "102010", quantity: 6 },
      { sku: "102011", quantity: 4 },
      { sku: "102013", quantity: 6 },
      { sku: "102014", quantity: 4 },
    ],
    is_best_value: false,
    subscription: true,
    images: [
      {
        src: "https://asset.basefood.co.jp/lp/common/lp_set_content_32_0626.png",
        aspectRatio: "164/135",
      },
    ],
    sub_title: "からだを変えたいなら",
  },
  {
    name: "家族の健康習慣のために 54袋セット",
    products: [
      { sku: "102007", quantity: 6 },
      { sku: "102008", quantity: 8 },
      { sku: "102009", quantity: 8 },
      { sku: "102010", quantity: 8 },
      { sku: "102011", quantity: 6 },
      { sku: "102012", quantity: 6 },
      { sku: "102013", quantity: 6 },
      { sku: "102014", quantity: 6 },
    ],
    is_best_value: false,
    subscription: true,
    images: [
      {
        src: "https://asset.basefood.co.jp/lp/common/lp_set_content_54_0626.png",
        aspectRatio: "559/701",
      },
    ],
    sub_title: "家族の健康習慣のために",
  },
];
