import clsx from "clsx";

import { PasswordInput } from "../AddressForm/PasswordInput";
import { AddressValues, UserCredentialsValues } from "../CheckoutForm/schema";
import { FieldLabel } from "../FieldLabel";
import { MarketingCheckbox } from "../MarketingCheckbox/MarketingCheckbox";

interface AddressFormForAmazonPayProps {
  address: Partial<AddressValues>;
  showPasswordInput: boolean;
  onClickChangeAddress: () => void;
  credentials: Partial<UserCredentialsValues>;
  onChangeCredentials: (values: Partial<UserCredentialsValues>) => void;
}

export function AddressFormForAmazonPay({
  address,
  credentials,
  showPasswordInput,
  onClickChangeAddress,
  onChangeCredentials,
}: AddressFormForAmazonPayProps) {
  return (
    <div>
      <div>
        <p className={clsx("text__m", "mg__bottom__s")}>
          <span className="text__yellow"> 配送先住所 </span>
          <button className={clsx("btn", "inline", "mini", "gray")} onClick={onClickChangeAddress}>
            変更
          </button>
        </p>
        <p className={clsx("text__m", "mg__bottom__m")}>
          {address?.lastName}
          {address?.firstName} 様
          <br />
          {address?.zip}
          <br />
          {address?.province} {address?.city} {address?.addressLine1} {address?.addressLine2}
        </p>
        <p className={clsx("text__m", "text__yellow", "mg__bottom__s")}>電話番号</p>
        <p className={clsx("text__m", "mg__bottom__m")}>{address?.phone}</p>
      </div>
      <MarketingCheckbox
        tooltipId="marketing-accept-tooltip"
        name="acceptsMarketing"
        checked={credentials.acceptsMarketing}
        onChange={(e) =>
          onChangeCredentials({ ...credentials, acceptsMarketing: e.target.checked })
        }
      />
      <div className={clsx("row", "pd__bottom__m")}>
        <div className="col-12" />
      </div>
      {showPasswordInput && (
        <div className="row">
          <div className="col-12">
            <FieldLabel showUnfilled={!credentials.password}>パスワード</FieldLabel>
          </div>
          <div className={clsx("col-12", "mg__bottom__l")}>
            <PasswordInput
              name="password"
              value={credentials.password}
              onChange={(e) => onChangeCredentials({ ...credentials, password: e.target.value })}
            />
          </div>
        </div>
      )}
    </div>
  );
}
