import { z } from "zod";

export const BankTransferFormSchema = z.object({
  lastName: z.string().min(1),
  firstName: z.string().min(1),
  lastNameKana: z.string().min(1),
  firstNameKana: z.string().min(1),
});

export type BankTransferFormValues = z.infer<typeof BankTransferFormSchema>;
