import clsx from "clsx";

import { MobileHeader } from "./MobileHeader";
import { PCHeader } from "./PCHeader";
import styles from "./TheHeaderJp.module.scss";

export function TheHeaderJP(): React.ReactNode {
  return (
    <header className={clsx(styles.headerFull)}>
      <PCHeader />
      <MobileHeader />
    </header>
  );
}
