import { useMemo } from "react";

import { useGetCustomerSuspense } from "@/generated/open-api/customer/customer";
import { convertApiCustomerToCustomer } from "@/models/customer/converters";

export const useParsedGetCustomer = () => {
  const { data, ...rest } = useGetCustomerSuspense();
  const parsed = useMemo(() => convertApiCustomerToCustomer(data.customer), [data]);
  return { data: parsed, ...rest };
};
