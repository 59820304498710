"use client";
import { type PropsWithChildren } from "react";

import clsx from "clsx";
import { Toaster } from "react-hot-toast";

import styles from "./ToastProvider.module.scss";

export interface ToastProviderProps extends PropsWithChildren {}

export const defaultToastClassNames = {
  success: clsx(styles.common, styles.success),
  error: clsx(styles.common, styles.error),
};

export function ToastProvider({ children }: ToastProviderProps): React.ReactNode {
  return (
    <>
      {children}
      <Toaster
        position="bottom-center"
        containerClassName={styles.container}
        gutter={10}
        toastOptions={{
          icon: null,
          duration: 5000,
          success: { className: defaultToastClassNames.success },
          error: { className: defaultToastClassNames.error },
        }}
      />
    </>
  );
}
