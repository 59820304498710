"use client";

import clsx from "clsx";

import { Scroll, FixedContainer, Image, Button } from "@/components";
import { FOOTER_BUTTON_TARGET_ID } from "@/components/domains/lp/consts";

import { useIsVisible } from "./helper";
import styles from "./ScrollButton.module.scss";

const ScrollButtonPatterns = ["simple", "yellow", "green", "pink"] as const;

export type ScrollButtonPattern = (typeof ScrollButtonPatterns)[number];

interface ScrollButtonProps {
  pattern: ScrollButtonPattern;
}

export function ScrollButton({ pattern }: ScrollButtonProps): React.ReactNode {
  const { isVisible } = useIsVisible();

  return (
    isVisible && (
      <FixedContainer
        id="product-fixed-section" // nuxtにあったid。どこで使用しているか不明
        className={clsx(pattern === "simple" && "bg__white")}
      >
        <Scroll to={FOOTER_BUTTON_TARGET_ID} className={styles.inner}>
          {pattern === "simple" && (
            <Button variants="yellow" rounded className={clsx("text__m text__bold")}>
              {" 【Amazon Pay対応】30秒で購入 "}
              <i className={clsx("fas fa-angle-down", styles.icon)}></i>
            </Button>
          )}
          {pattern === "yellow" && (
            <Image
              src="https://asset.basefood.co.jp/lp/diet_redesign/btn_chocolate_v1_12.png"
              alt="ご購入はこちらから"
              aspectRatio="751 / 162"
            />
          )}
          {pattern === "green" && (
            <Image
              src="https://asset.basefood.co.jp/lp/diet_04/0805_cta01_btn_float.png?0729"
              alt="継続コースをお得にはじめる"
              aspectRatio="375 / 109"
            />
          )}
          {pattern === "pink" && (
            <Image
              src="https://asset.basefood.co.jp/lp/diet_03_2/btn.png"
              alt="購入ボタン"
              aspectRatio="125 / 38"
            />
          )}
        </Scroll>
      </FixedContainer>
    )
  );
}
