"use client";
import React, { useCallback, useState } from "react";

import clsx from "clsx";
import { Tooltip } from "react-tooltip";

import { Row } from "@/components/containers";
import { Input, Selector } from "@/components/inputs";
import { Checkbox } from "@/components/inputs/Checkbox";
import { nl2br } from "@/utils/jsx";
import { provinces } from "@/utils/province";
import { zeroPadding } from "@/utils/string";
import { getAddressByZipCode } from "@/utils/zipCode";

import styles from "./AddressForm.module.scss";
import { PasswordInput } from "./PasswordInput";
import { AddressValues, UserCredentialsValues } from "../CheckoutForm/schema";
import { FieldLabel } from "../FieldLabel";

interface AddressFormWithCredentialsProps {
  withCredentials: true;
  credentials: Partial<UserCredentialsValues>;
  onChangeCredentials: (values: Partial<UserCredentialsValues>) => void;
}

interface AddressFormWithoutCredentialsProps {
  withCredentials: false;
  credentials?: undefined;
  onChangeCredentials?: undefined;
}

type AddressFormProps = {
  isLoggedIn?: boolean; // ログインしている場合は、メールアドレス入力不可、パスワード非表示、ログアウトボタンを表示する
  showLogout: boolean;
  address: Partial<AddressValues>;
  onChangeAddress: (values: Partial<AddressValues>) => void;
  onChangedEmail?: (email: string) => void;
} & (AddressFormWithCredentialsProps | AddressFormWithoutCredentialsProps);

export function AddressForm({
  isLoggedIn = false,
  showLogout,
  address,
  onChangeAddress,
  withCredentials,
  credentials,
  onChangeCredentials,
  onChangedEmail,
}: AddressFormProps): React.ReactNode {
  const [provinceDisabled, setProvinceDisabled] = useState(false);

  const handleChangeAddress = useCallback(
    (newValues: Partial<AddressValues>) => {
      onChangeAddress({
        ...address,
        ...newValues,
      });
    },
    [address, onChangeAddress]
  );

  const handleChangeCredentials = useCallback(
    (newValues: Partial<UserCredentialsValues>) => {
      if (withCredentials) {
        onChangeCredentials({
          ...credentials,
          ...newValues,
        });
      }
    },
    [credentials, withCredentials, onChangeCredentials]
  );

  const fillAddressFromZip = useCallback(
    async (zipCode: string) => {
      zipCode = zipCode.replace(/[^0-9]/g, "");
      if (zipCode.length !== 7) {
        setProvinceDisabled(false);
        return;
      }
      if (zipCode.length === 7) {
        const postalAddress = await getAddressByZipCode(zipCode);
        if (!postalAddress) return;
        const provinceCode = `JP-${zeroPadding(postalAddress.provinceId, 2)}`;
        const hasProvince = provinces.find((prefecture) => provinceCode === prefecture.code);
        if (hasProvince) {
          handleChangeAddress({
            province: provinceCode,
            city: postalAddress.locality,
            addressLine1: postalAddress.street,
          });
          setProvinceDisabled(true);
        }
      }
    },
    [handleChangeAddress]
  );

  return (
    <div className={clsx("row", "mg__bottom__l")}>
      <div style={{ width: "100%" }}>
        <Row>
          <div className="col-12">
            <FieldLabel showUnfilled={!address.lastName || !address.firstName}>お名前</FieldLabel>
          </div>
        </Row>
        <div className={clsx(styles.nameRow)}>
          <div className={clsx("col-12", "col-m-6")}>
            <Input
              name="lastName"
              type="text"
              placeholder="姓"
              value={address.lastName}
              onChange={(e) => handleChangeAddress({ lastName: e.target.value })}
            />
          </div>
          <div className={clsx("col-12", "col-m-6", "pd__top__s", "pd__top__off__pc")}>
            <Input
              name="firstName"
              type="text"
              placeholder="名"
              value={address.firstName}
              onChange={(e) => handleChangeAddress({ firstName: e.target.value })}
            />
          </div>
        </div>
      </div>
      {withCredentials && (
        <div className="col-12">
          <FieldLabel showUnfilled={!credentials?.email}>
            メールアドレス
            {isLoggedIn && showLogout && (
              <button
                className={clsx("btn", "inline", "gray", "mini", "angle__right")}
                // onClick={logout_checkout}
              >
                ログアウト
                <i className={clsx("fas", "fa-angle-right")} />
              </button>
            )}
          </FieldLabel>
          <Input
            name="email"
            type="email"
            placeholder="example@example.com"
            value={credentials?.email ?? ""}
            disabled={isLoggedIn}
            onChange={(e) => handleChangeCredentials({ email: e.target.value })}
            onBlur={(e) => onChangedEmail?.(e.target.value)}
          />

          <div className={clsx("mg__top__s", "mg__bottom__s")}>
            <p className={clsx("text__s", "text__gray__dark")}>
              ※docomo,au,softbankのキャリアメールはメールが届かない場合がございます。
              <br />
              上記以外のメールアドレスのご利用を推奨しております。
            </p>
          </div>
          <Checkbox
            name="acceptsMarketing"
            checked={credentials.acceptsMarketing ?? false}
            onChange={(e) => handleChangeCredentials({ acceptsMarketing: e.target.checked })}
          >
            <Row>
              ベースフードからのお知らせを受け取る
              <i className={clsx("fas fa-question-circle text__m", styles.questionIcon)} />
              <Tooltip anchorSelect="#marketing-accept-tooltip">
                {nl2br(
                  `お得で便利な情報をお届けします。\n・新商品試食会へのご案内\n・限定割引クーポン\n・おいしい食べ方など`
                )}
              </Tooltip>
            </Row>
          </Checkbox>
        </div>
      )}

      {withCredentials && !isLoggedIn && (
        <div className={clsx("col-12", "col-m-6")}>
          <FieldLabel showUnfilled={!credentials.password}>パスワード</FieldLabel>
          <PasswordInput
            name="password"
            value={credentials.password}
            onChange={(e) => handleChangeCredentials({ password: e.target.value })}
          />
        </div>
      )}

      <Row style={{ flexWrap: "wrap", width: "100%" }}>
        <div className={clsx("col-12")}>
          <FieldLabel showUnfilled={!address.zip}>郵便番号</FieldLabel>
        </div>
        <div className={clsx("col-12", "col-m-6")}>
          <Input
            name="zip"
            value={address.zip}
            onChange={(e) => handleChangeAddress({ zip: e.target.value })}
            // 入力が完了した時点で都道府県・市区町村を自動入力する関数を呼び出す
            onBlur={(e) => fillAddressFromZip(e.target.value)}
            type="tel"
            placeholder="000000"
          />
        </div>
        <div className={clsx("col-12", "col-m-6")}>
          <p className={clsx("text__s", "pd__top__s")}>都道府県・市区町村が自動入力されます</p>
        </div>
      </Row>

      <div className={clsx("col-12", "col-m-6")}>
        <FieldLabel>都道府県</FieldLabel>
        <Selector
          name="province"
          disabled={provinceDisabled}
          options={provinces.map((prefecture) => {
            return { label: prefecture.jp, value: prefecture.code };
          })}
          value={address.province}
          onChange={(province) => handleChangeAddress({ province })}
        />
      </div>

      <div className={clsx("col-12", "col-m-6")}>
        <FieldLabel showUnfilled={!address.city}>市区町村</FieldLabel>
        <Input
          name="city"
          value={address.city}
          type="text"
          placeholder="目黒区"
          onChange={(e) => handleChangeAddress({ city: e.target.value })}
        />
      </div>

      <div className="col-12">
        <FieldLabel showUnfilled={!address.addressLine1}>町名・番地</FieldLabel>
        <Input
          name="addressLine1"
          value={address.addressLine1}
          type="text"
          placeholder="町名・番地"
          onChange={(e) => handleChangeAddress({ addressLine1: e.target.value })}
        />
      </div>

      <div className="col-12">
        <FieldLabel>建物名・部屋番号</FieldLabel>
        <Input
          name="addressLine2"
          value={address.addressLine2}
          type="text"
          placeholder="建物名・部屋番号"
          onChange={(e) => handleChangeAddress({ addressLine2: e.target.value })}
        />
      </div>

      <div className="col-12">
        <FieldLabel showUnfilled={!address.phone}>電話番号</FieldLabel>
        <Input
          name="phone"
          value={address.phone}
          type="tel"
          placeholder="0312345678"
          onChange={(e) => handleChangeAddress({ phone: e.target.value })}
        />
      </div>
    </div>
  );
}
