import React from "react";

import clsx from "clsx";
import Link from "next/link";

import styles from "./Breadcrumb.module.scss";

type BreadcrumbItem = {
  path: string;
  title: string;
};

export type BreadcrumbProps = {
  current: string;
  items?: BreadcrumbItem[];
  textClassName?: string;
};

export const Breadcrumb = ({ current, items, textClassName }: BreadcrumbProps) => {
  return (
    <div className={clsx("row mg__bottom__m mg__top__m", styles.root)}>
      <div className="col-12">
        <p className="text__s">
          <i className={clsx("fas fa-angle-right", styles.arrow)} />
          {items?.map((_item, index) => {
            return (
              <React.Fragment key={index}>
                <Link href={_item.path}>
                  <span className={clsx("text__yellow", textClassName)}>{_item.title}</span>
                </Link>
                <i className={clsx("fas fa-angle-right", styles.arrow)} />
              </React.Fragment>
            );
          })}
          <span className={clsx(items?.length && "text__red")}>{current}</span>
        </p>
      </div>
    </div>
  );
};
