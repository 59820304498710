import clsx from "clsx";

import styles from "./Column.module.scss";

export type ColumnProps = React.ComponentProps<"div">;

export function Column(props: ColumnProps): React.ReactNode {
  const { className, ...rest } = props;
  return <div className={clsx(styles.root, className)} {...rest} />;
}
