import { getDomesticProvince, provinces } from "./province";

interface PostalAddress {
  provinceId: number;
  province: string;
  locality: string; // => city
  street: string; // => addressLine1
  extended: string;
}

interface CacheType {
  [key: string]: {
    [key: string]: string[];
  };
}

const CACHE: CacheType = {};
const URL = "https://yubinbango.github.io/yubinbango-data/data";
const REGION = [null, ...provinces.map((prefecture) => prefecture.jp)];

function validateFormat(code: string): boolean {
  // 数字7桁か、数字3桁-数字4桁の形式
  return /^\d{7}$|^\d{3}-\d{4}$/.test(code);
}

function selectAddress(addressParts: string[]): PostalAddress | null {
  if (!addressParts || !addressParts[0] || !addressParts[1]) {
    return null;
  }
  const provinceIndex = parseInt(addressParts[0], 10);
  const provinceName = REGION[provinceIndex] ?? "";
  return {
    province: provinceName,
    provinceId: parseInt(addressParts[0]),
    locality: addressParts[1],
    street: addressParts[2],
    extended: addressParts[3] ?? "",
  };
}

function fetchZipCodeData(url: string): Promise<{ [zipCode: string]: string[] }> {
  return new Promise((resolve, reject) => {
    const callbackName = `$yubin`;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any)[callbackName] = function (data: { [zipCode: string]: string[] }) {
      resolve(data);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      delete (window as any)[callbackName];
    };

    const script = document.createElement("script");
    script.type = "text/javascript";
    script.charset = "UTF-8";
    script.src = `${url}?callback=${callbackName}`;
    script.onerror = () => {
      reject(new Error("JSONP request failed"));
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      delete (window as any)[callbackName];
    };
    document.head.appendChild(script);
  });
}

async function getAddressByZipCode(zipCode: string): Promise<PostalAddress | null> {
  if (!validateFormat(zipCode)) {
    throw new Error("Invalid ZIP code length");
  }
  const normalizedZipCode = zipCode.replace("-", "");
  const regionCode = normalizedZipCode.slice(0, 3);
  if (CACHE[regionCode]) {
    return selectAddress(CACHE[regionCode][normalizedZipCode] ?? []);
  }
  try {
    const addresses = await fetchZipCodeData(`${URL}/${regionCode}.js`);
    CACHE[regionCode] = { ...addresses, ...(CACHE[regionCode] || {}) };
    return selectAddress(addresses[normalizedZipCode] || []);
  } catch (error) {
    throw error; // 外部でキャッチ可能にする
  }
}

async function isValidZipCode(zipCode: string): Promise<boolean> {
  try {
    const address = await getAddressByZipCode(zipCode);
    return Boolean(address);
  } catch {
    return false;
  }
}

function isValidProvince(provinceName: string): boolean {
  const province = getDomesticProvince(provinceName);
  return Boolean(province);
}

export { getAddressByZipCode, isValidZipCode, isValidProvince };
