export const PaymentMethod = {
  credit: "credit",
  konbini: "konbini",
  ginkou: "ginkou",
  daibiki: "daibiki",
  amazon: "amazon",
  pointOnly: "pointOnly",
} as const;
export type PaymentMethod = keyof typeof PaymentMethod;

export const paymentMethodLabels: Record<PaymentMethod, string> = {
  [PaymentMethod.credit]: "クレジットカード支払い",
  [PaymentMethod.konbini]: "コンビニ支払い",
  [PaymentMethod.ginkou]: "銀行振込",
  [PaymentMethod.daibiki]: "代金引換",
  [PaymentMethod.amazon]: "Amazon Pay",
  [PaymentMethod.pointOnly]: "ポイントのみ",
};

export const ConvenienceStore = {
  DailyYamazaki: "daily-yamazaki",
  FamilyMart: "family-mart",
  Lawson: "lawson",
  MiniStop: "ministop",
  SeicoMart: "seicomart",
  SevenEleven: "seveneleven",
} as const;

export type ConvenienceStore = (typeof ConvenienceStore)[keyof typeof ConvenienceStore];

export const convenienceStoreLabels: Record<ConvenienceStore, string> = {
  [ConvenienceStore.DailyYamazaki]: "デイリーヤマザキ",
  [ConvenienceStore.FamilyMart]: "ファミリーマート",
  [ConvenienceStore.Lawson]: "ローソン",
  [ConvenienceStore.MiniStop]: "ミニストップ",
  [ConvenienceStore.SeicoMart]: "セイコーマート",
  [ConvenienceStore.SevenEleven]: "セブンイレブン",
};
