import { type LineupSectionItemProps } from "./LineupSectionItem";

export const items: LineupSectionItemProps[] = [
  {
    title: "チョコレート",
    subTitle: "しっとりチョコを折り込んだ",
    imageUrl: "https://asset.basefood.co.jp/images/pkg/thumb_bb_chocolate_v1_12_omote.png",
    description: "マーブルチョコパン",
    quantity: "1袋1個入り",
  },
  {
    title: "メープル",
    subTitle: "口に広がるやさしい甘み",
    imageUrl: "https://asset.basefood.co.jp/images/pkg/thumb_bb_maple_v1_6_omote.png",
    description: "ミニパン",
    quantity: "1袋2個入り",
  },
  {
    title: "シナモン",
    subTitle: "本格シナモンの豊かな香り",
    imageUrl: "https://asset.basefood.co.jp/images/pkg/thumb_bb_cinnamon_v1_6_omote.png",
    description: "ミニパン",
    quantity: "1袋2個入り",
  },
  {
    title: "ミニ食パン・レーズン",
    subTitle: "じゅわっと広がるレーズンの甘み",
    imageUrl: "https://asset.basefood.co.jp/images/pkg/thumb_bb_slice_raisin_v1_3_omote.png",
    description: "ミニ食パン",
    quantity: "1袋2枚入り",
  },
  {
    title: "リッチ",
    subTitle: "ふっくらまろやか、コクゆたか",
    imageUrl: "https://asset.basefood.co.jp/images/pkg/thumb_bb_rich_v1_4_omote.png",
    description: "ロールパン",
    quantity: "1袋1個入り",
  },
  {
    title: "カレー",
    subTitle: "やわもちパンに、濃厚カレー",
    imageUrl: "https://asset.basefood.co.jp/images/pkg/thumb_bb_curry_v1_6_omote.png",
    description: "惣菜パン",
    quantity: "1袋1個入り",
  },
  {
    title: "プレーン",
    subTitle: "ふんわり食感の全粒粉パン",
    imageUrl: "https://asset.basefood.co.jp/images/pkg/thumb_bb_plain_v3_11_omote.png",
    description: "ロールパン",
    quantity: "1袋1個入り",
  },
  {
    title: "ミニ食パン・プレーン",
    imageUrl: "https://asset.basefood.co.jp/images/pkg/thumb_bb_slice_plain_v1_5_omote.png",
    description: "ミニ食パン",
    subTitle: "食べやすい全粒粉パン",
    quantity: "1袋2枚入り",
  },
];
