import React from "react";

import Link from "next/link";

export interface MiniLinkButtonProps {
  children: React.ReactNode;
  to?: string;
  href?: string;
  color?: string;
  arrow?: string;
}

/**
 * from src/components/atoms/BtnLinkMini.vue
 * @returns
 */
export function MiniLinkButton({ to, href, color = "gray", arrow, children }: MiniLinkButtonProps) {
  const Component = to ? Link : "a";
  const linkProps = to ? { href: to } : {};
  const anchorProps = href ? { href } : { href: "" };

  return (
    <Component {...linkProps} {...anchorProps} className={`btn inline mini ${color} ${arrow}`}>
      {children}
      {arrow === "angle__right" && <i className="fas fa-angle-right" />}
    </Component>
  );
}
