"use client";
import { useState } from "react";

import clsx from "clsx";

import { Column, Row } from "@/components/containers";

import styles from "./Accordion.module.scss";

export interface AccordionProps {
  title: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  openIcon?: React.ReactNode;
  closeIcon?: React.ReactNode;
}

export function Accordion({
  title,
  children,
  className,
  openIcon = (
    <img
      className={styles.iconOpen}
      src={"https://asset.basefood.co.jp/images/navi/icon-plus.png"}
      alt={"close"}
    />
  ),
  closeIcon = (
    <img
      className={styles.iconClose}
      src={"https://asset.basefood.co.jp/images/navi/icon-minus.png"}
      alt={"open"}
    />
  ),
}: AccordionProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Column className={clsx(className)}>
      <Row
        className={clsx(styles.titleContainer, "text__m text__bold")}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <Row>{title}</Row>
        {isOpen ? closeIcon : openIcon}
      </Row>
      <Column className={clsx("text__m", styles.body, isOpen ? styles.bodyOpen : styles.bodyClose)}>
        {children}
      </Column>
    </Column>
  );
}
