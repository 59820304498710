export interface Province {
  id: number;
  code: string;
  jp: string;
  en: string;
  hepburn: string;
  supportDropOff: boolean; // 置き配に対応しているかどうか
}

// prettier-ignore
export const provinces: Province[] = [
  { id: 1, code: "JP-01", jp: "北海道", en: "Hokkaidō", hepburn: "Hokkaido", supportDropOff: false},
  { id: 2, code: "JP-02", jp: "青森県", en: "Aomori", hepburn: "Aomori", supportDropOff: false},
  { id: 3, code: "JP-03", jp: "岩手県", en: "Iwate", hepburn: "Iwate", supportDropOff: false},
  { id: 4, code: "JP-04", jp: "宮城県", en: "Miyagi", hepburn: "Miyagi", supportDropOff: false},
  { id: 5, code: "JP-05", jp: "秋田県", en: "Akita", hepburn: "Akita", supportDropOff: false},
  { id: 6, code: "JP-06", jp: "山形県", en: "Yamagata", hepburn: "Yamagata", supportDropOff: false},
  { id: 7, code: "JP-07", jp: "福島県", en: "Fukushima", hepburn: "Fukushima", supportDropOff: false},
  { id: 8, code: "JP-08", jp: "茨城県", en: "Ibaraki", hepburn: "Ibaraki", supportDropOff: false},
  { id: 9, code: "JP-09", jp: "栃木県", en: "Tochigi", hepburn: "Tochigi", supportDropOff: false},
  { id: 10, code: "JP-10", jp: "群馬県", en: "Gunma", hepburn: "Gunma", supportDropOff: false},
  { id: 11, code: "JP-11", jp: "埼玉県", en: "Saitama", hepburn: "Saitama", supportDropOff: true},
  { id: 12, code: "JP-12", jp: "千葉県", en: "Chiba", hepburn: "Chiba", supportDropOff: true},
  { id: 13, code: "JP-13", jp: "東京都", en: "Tōkyō", hepburn: "Tokyo", supportDropOff: true},
  { id: 14, code: "JP-14", jp: "神奈川県", en: "Kanagawa", hepburn: "Kanagawa", supportDropOff: true},
  { id: 15, code: "JP-15", jp: "新潟県", en: "Niigata", hepburn: "Niigata", supportDropOff: false},
  { id: 16, code: "JP-16", jp: "富山県", en: "Toyama", hepburn: "Toyama", supportDropOff: false},
  { id: 17, code: "JP-17", jp: "石川県", en: "Ishikawa", hepburn: "Ishikawa", supportDropOff: false},
  { id: 18, code: "JP-18", jp: "福井県", en: "Fukui", hepburn: "Fukui", supportDropOff: false},
  { id: 19, code: "JP-19", jp: "山梨県", en: "Yamanashi", hepburn: "Yamanashi", supportDropOff: false},
  { id: 20, code: "JP-20", jp: "長野県", en: "Nagano", hepburn: "Nagano", supportDropOff: false},
  { id: 21, code: "JP-21", jp: "岐阜県", en: "Gifu", hepburn: "Gifu", supportDropOff: false},
  { id: 22, code: "JP-22", jp: "静岡県", en: "Shizuoka", hepburn: "Shizuoka", supportDropOff: false},
  { id: 23, code: "JP-23", jp: "愛知県", en: "Aichi", hepburn: "Aichi", supportDropOff: false},
  { id: 24, code: "JP-24", jp: "三重県", en: "Mie", hepburn: "Mie", supportDropOff: false},
  { id: 25, code: "JP-25", jp: "滋賀県", en: "Shiga", hepburn: "Shiga", supportDropOff: false},
  { id: 26, code: "JP-26", jp: "京都府", en: "Kyōto", hepburn: "Kyoto", supportDropOff: false},
  { id: 27, code: "JP-27", jp: "大阪府", en: "Ōsaka", hepburn: "Osaka", supportDropOff: false},
  { id: 28, code: "JP-28", jp: "兵庫県", en: "Hyōgo", hepburn: "Hyogo", supportDropOff: false},
  { id: 29, code: "JP-29", jp: "奈良県", en: "Nara", hepburn: "Nara", supportDropOff: false},
  { id: 30, code: "JP-30", jp: "和歌山県", en: "Wakayama", hepburn: "Wakayama", supportDropOff: false},
  { id: 31, code: "JP-31", jp: "鳥取県", en: "Tottori", hepburn: "Tottori", supportDropOff: false},
  { id: 32, code: "JP-32", jp: "島根県", en: "Shimane", hepburn: "Shimane", supportDropOff: false},
  { id: 33, code: "JP-33", jp: "岡山県", en: "Okayama", hepburn: "Okayama", supportDropOff: false},
  { id: 34, code: "JP-34", jp: "広島県", en: "Hiroshima", hepburn: "Hiroshima", supportDropOff: false},
  { id: 35, code: "JP-35", jp: "山口県", en: "Yamaguchi", hepburn: "Yamaguchi", supportDropOff: false},
  { id: 36, code: "JP-36", jp: "徳島県", en: "Tokushima", hepburn: "Tokushima", supportDropOff: false},
  { id: 37, code: "JP-37", jp: "香川県", en: "Kagawa", hepburn: "Kagawa", supportDropOff: false},
  { id: 38, code: "JP-38", jp: "愛媛県", en: "Ehime", hepburn: "Ehime", supportDropOff: false},
  { id: 39, code: "JP-39", jp: "高知県", en: "Kōchi", hepburn: "Kochi", supportDropOff: false},
  { id: 40, code: "JP-40", jp: "福岡県", en: "Fukuoka", hepburn: "Fukuoka", supportDropOff: false},
  { id: 41, code: "JP-41", jp: "佐賀県", en: "Saga", hepburn: "Saga", supportDropOff: false},
  { id: 42, code: "JP-42", jp: "長崎県", en: "Nagasaki", hepburn: "Nagasaki", supportDropOff: false},
  { id: 43, code: "JP-43", jp: "熊本県", en: "Kumamoto", hepburn: "Kumamoto", supportDropOff: false},
  { id: 44, code: "JP-44", jp: "大分県", en: "Ōita", hepburn: "Oita", supportDropOff: false},
  { id: 45, code: "JP-45", jp: "宮崎県", en: "Miyazaki", hepburn: "Miyazaki", supportDropOff: false},
  { id: 46, code: "JP-46", jp: "鹿児島県", en: "Kagoshima", hepburn: "Kagoshima", supportDropOff: false},
  { id: 47, code: "JP-47", jp: "沖縄県", en: "Okinawa", hepburn: "Okinawa", supportDropOff: false},
];

const usaProvinces = [
  { code: "AL", name: "Alabama" },
  { code: "AK", name: "Alaska" },
  { code: "AS", name: "American Samoa" },
  { code: "AZ", name: "Arizona" },
  { code: "AR", name: "Arkansas" },
  { code: "CA", name: "California" },
  { code: "CO", name: "Colorado" },
  { code: "CT", name: "Connecticut" },
  { code: "DE", name: "Delaware" },
  { code: "DC", name: "District of Columbia" },
  { code: "FM", name: "Federated States of Micronesia" },
  { code: "FL", name: "Florida" },
  { code: "GA", name: "Georgia" },
  { code: "GU", name: "Guam" },
  { code: "HI", name: "Hawaii" },
  { code: "ID", name: "Idaho" },
  { code: "IL", name: "Illinois" },
  { code: "IN", name: "Indiana" },
  { code: "IA", name: "Iowa" },
  { code: "KS", name: "Kansas" },
  { code: "KY", name: "Kentucky" },
  { code: "LA", name: "Louisiana" },
  { code: "ME", name: "Maine" },
  { code: "MH", name: "Marshall Islands" },
  { code: "MD", name: "Maryland" },
  { code: "MA", name: "Massachusetts" },
  { code: "MI", name: "Michigan" },
  { code: "MN", name: "Minnesota" },
  { code: "MS", name: "Mississippi" },
  { code: "MO", name: "Missouri" },
  { code: "MT", name: "Montana" },
  { code: "NE", name: "Nebraska" },
  { code: "NV", name: "Nevada" },
  { code: "NH", name: "New Hampshire" },
  { code: "NJ", name: "New Jersey" },
  { code: "NM", name: "New Mexico" },
  { code: "NY", name: "New York" },
  { code: "NC", name: "North Carolina" },
  { code: "ND", name: "North Dakota" },
  { code: "MP", name: "Northern Mariana Islands" },
  { code: "OH", name: "Ohio" },
  { code: "OK", name: "Oklahoma" },
  { code: "OR", name: "Oregon" },
  { code: "PW", name: "Palau" },
  { code: "PA", name: "Pennsylvania" },
  { code: "PR", name: "Puerto Rico" },
  { code: "RI", name: "Rhode Island" },
  { code: "SC", name: "South Carolina" },
  { code: "SD", name: "South Dakota" },
  { code: "TN", name: "Tennessee" },
  { code: "TX", name: "Texas" },
  { code: "UT", name: "Utah" },
  { code: "VT", name: "Vermont" },
  { code: "VI", name: "Virgin Islands" },
  { code: "VA", name: "Virginia" },
  { code: "WA", name: "Washington" },
  { code: "WV", name: "West Virginia" },
  { code: "WI", name: "Wisconsin" },
  { code: "WY", name: "Wyoming" },
  { code: "AA", name: "Armed Forces Americas" },
  { code: "AE", name: "Armed Forces Europe" },
  { code: "AP", name: "Armed Forces Pacific" },
];

/**
 * AmazonPayなどでprovinceの値がJP-XXの形式だったり、都道府県名だったりするので、それに対応するための関数
 * @param codeOrName
 * @returns
 */
export const getDomesticProvince = (codeOrName: string) => {
  return provinces.find(
    (province) =>
      province.code.toLocaleLowerCase() === codeOrName.toLocaleLowerCase() ||
      province.jp.toLocaleLowerCase() === codeOrName.toLocaleLowerCase() ||
      province.en.toLocaleLowerCase() === codeOrName.toLocaleLowerCase() ||
      province.hepburn.toLocaleLowerCase() === codeOrName.toLocaleLowerCase()
  );
};

export const getForeignProvince = (codeOrName: string) => {
  return usaProvinces.find(
    (province) =>
      province.code.toLocaleLowerCase() === codeOrName.toLocaleLowerCase() ||
      province.name.toLocaleLowerCase() === codeOrName.toLocaleLowerCase()
  );
};

/**
 * vueのprovinceFormat
 * @param codeOrName
 * @returns
 */
export const getProvinceName = (codeOrName: string) => {
  const domesticProvince = getDomesticProvince(codeOrName);
  if (domesticProvince) {
    return domesticProvince.jp;
  }

  const foreignProvince = getForeignProvince(codeOrName);
  if (foreignProvince) {
    return foreignProvince.name;
  }

  return codeOrName;
};
