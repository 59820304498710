import { type RecommendSet } from "./types";

export const LP_DIET_02: RecommendSet[] = [
  {
    name: "まずはおためし 14袋セット",
    products: [
      { sku: "102008", quantity: 4 },
      { sku: "102009", quantity: 2 },
      { sku: "102010", quantity: 2 },
      { sku: "102011", quantity: 2 },
      { sku: "102013", quantity: 2 },
      { sku: "102014", quantity: 2 },
    ],
    is_best_value: false,
    subscription: true,
  },
  {
    name: "からだを変えたいなら 32袋セット",
    products: [
      { sku: "102008", quantity: 6 },
      { sku: "102009", quantity: 6 },
      { sku: "102010", quantity: 6 },
      { sku: "102011", quantity: 4 },
      { sku: "102013", quantity: 6 },
      { sku: "102014", quantity: 4 },
    ],
    is_best_value: false,
    subscription: true,
  },
  {
    name: "家族の健康習慣のために 54袋セット",
    products: [
      { sku: "102007", quantity: 6 },
      { sku: "102008", quantity: 8 },
      { sku: "102009", quantity: 8 },
      { sku: "102010", quantity: 8 },
      { sku: "102011", quantity: 6 },
      { sku: "102012", quantity: 6 },
      { sku: "102013", quantity: 6 },
      { sku: "102014", quantity: 6 },
    ],
    is_best_value: false,
    subscription: true,
  },
];
