import {
  frozenTemperatureProductsNames,
  normalTemperatureProductsNames,
} from "@/models/product/consts";

import { COOKIES_01 } from "./COOKIES_01";
import { FORM_DEFAULT_SELECTED } from "./FORM_DEFAULT_SELECTED";
import { INVITE_SUBSCRIPTION_COUPON_DISCOUNT_DEFAULT_SELECTED } from "./INVITE_SUBSCRIPTION_COUPON_DISCOUNT_DEFAULT_SELECTED";
import { INVITE_SUBSCRIPTION_COUPON_DISCOUNT_REQUIRED } from "./INVITE_SUBSCRIPTION_COUPON_DISCOUNT_REQUIRED";
import { LP_05_DEFAULT_SELECTED } from "./LP_05_DEFAULT_SELECTED";
import { LP_DIET_01 } from "./LP_DIET_01";
import { LP_DIET_02 } from "./LP_DIET_02";
import { LP_DIET_05 } from "./LP_DIET_05";
import { LP_FORM_01 } from "./LP_FORM_01";
import { LP_FORM_02 } from "./LP_FORM_02";
import { LP_FORM_03 } from "./LP_FORM_03";
import { LP_FORM_CHATBOT } from "./LP_FORM_CHATBOT";
import { LP_FORM_DEFAULT } from "./LP_FORM_DEFAULT";
import { LP_VEGETABLE } from "./LP_VEGETABLE";
import { START_SET } from "./START_SET";

export const LPRecommendations = {
  COOKIES_01,
  FORM_DEFAULT_SELECTED,
  INVITE_SUBSCRIPTION_COUPON_DISCOUNT_DEFAULT_SELECTED,
  INVITE_SUBSCRIPTION_COUPON_DISCOUNT_REQUIRED,
  LP_05_DEFAULT_SELECTED,
  LP_DIET_01,
  LP_DIET_02,
  LP_DIET_05,
  LP_FORM_01,
  LP_FORM_02,
  LP_FORM_03,
  LP_FORM_CHATBOT,
  LP_FORM_DEFAULT,
  LP_VEGETABLE,
  START_SET,
} as const;

export type LPRecommendationsVariant = keyof typeof LPRecommendations;

export const AllProductsNames = {
  NormalTemperatureProductsNames: normalTemperatureProductsNames,
  FrozenTemperatureProductsNames: frozenTemperatureProductsNames,
};
