"use client";

import { useCallback, useMemo, useState } from "react";

import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { DeepPartial } from "react-hook-form";

import {
  AmazonPayPanel,
  FullScreenProgressBar,
  htmlToast,
  LoadingOverlay,
  ScrollAnchor,
  useAmazonPay,
  useAuth,
} from "@/components";
import { FOOTER_BUTTON_TARGET_ID } from "@/components/domains/lp/consts";
import { useLPStore } from "@/components/domains/lp/store";
import { useSaveCheckoutProgress } from "@/generated/open-api/cart/cart";
import { convertProductToNuxtStoreProduct } from "@/models/product/converters";
import type { ProductModel } from "@/models/product/type";
import { useClientCartOrder } from "@/storage/useClientCartOrder";
import { withCsr } from "@/utils";
import {
  fireAmazonPayAction,
  getFormDataForAmazonPay,
  setFormDataForAmazonPay,
  setupAmazonPay,
  getAmazonCheckoutUrl,
} from "@/utils/amazon";
import { getErrorMessages } from "@/utils/error";
import { useCount, useOnce } from "@/utils/hooks";
import { objectMerge } from "@/utils/object";

import { CheckoutConfirmation } from "./CheckoutConfirmation";
import { getUtmContent, PageFrom, useForm, useSyncCart } from "./helper";
import { OnePageCheckoutFormTitle } from "./OnePageCheckoutFormTitle";
import { SetSelector } from "./SetSelector";
import { SelectedProducts } from "./SetSelector/types";
import {
  CheckoutForm,
  CheckoutFormValues,
  convertFormValuesToStorage,
  getDefaultFormValues,
} from "../../checkout-form";
import { transformRecommendSetProductToSelectOption } from "../helpers";
import { LPRecommendations, LPRecommendationsVariant } from "../settings";

export interface OnePageCheckoutFormProps {
  products: ProductModel[];
  variant: LPRecommendationsVariant;
  isInvited?: boolean;
  pageFrom: PageFrom;
}

export const OnePageCheckoutForm = withCsr(function OnePageCheckoutForm({
  products,
  variant,
  isInvited = false,
  pageFrom,
}: OnePageCheckoutFormProps): React.ReactNode {
  const searchParams = useSearchParams();
  const [couponCode, useCoupon, forceSubmit, hideFreeSelect] = [
    searchParams.get("code") ?? undefined,
    !!searchParams.get("coupon"),
    searchParams.get("confirm") === "true",
    searchParams.get("hide_free_select") === "true",
  ] as const;

  const defaultCoupon = useCoupon ? couponCode : undefined;

  // 確認ページ切り替え用フラグ
  const [confirming, setConfirming] = useState(false);
  const router = useRouter();

  const { persistentForm, confirm, submit } = useForm();

  const { setAccessToken } = useAuth();
  const { setOrder } = useClientCartOrder();

  const [loadingCount, startLoading, endLoading] = useCount();
  const isLoading = loadingCount > 0;

  const scrollToFormTop = useCallback(() => {
    const element = document.getElementById("lp-form");
    if (!element) return;

    const rect = element.getBoundingClientRect();
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    const offset = rect.top + scrollTop;

    window.scrollTo(0, offset);
  }, []);

  const amazonPayData = useAmazonPay();
  const { amazonPayCheckoutSessionId, amazonPayCheckoutError, amazonPayEnable } = amazonPayData;

  // 注文商品
  const {
    selectIndex = persistentForm?.selectIndex ?? 1,
    setSelectIndex,
    isSubmitting,
    submittingProgress,
    resetSubmittingStatus,
  } = useLPStore();
  const [selectedProducts, setSelectedProducts] = useState<SelectedProducts>(() => {
    if (persistentForm?.products && persistentForm?.products.length > 0) {
      return (
        persistentForm?.products?.reduce<SelectedProducts>((acc, product) => {
          return {
            ...acc,
            [product.variant_id]: product.quantity,
          };
        }, {}) || {}
      );
    }
    return transformRecommendSetProductToSelectOption(
      products,
      LPRecommendations[variant][selectIndex]?.products
    );
  });

  const cart = useSyncCart(selectedProducts);

  const handleSelectedProductsChange = useCallback(
    async (newSelectedProducts: SelectedProducts, newSetIndex: number) => {
      setSelectedProducts(newSelectedProducts);
      setSelectIndex(newSetIndex);
    },
    [setSelectIndex]
  );

  // フォーム(商品選択を除く)
  const [formValues, setFormValues] = useState<DeepPartial<CheckoutFormValues>>(() =>
    objectMerge(
      getDefaultFormValues({
        localStorageForm: persistentForm,
        sessionStorageForm: getFormDataForAmazonPay("amazon_pay_input_data"),
        customer: null,
        amazonPayData,
      }),
      // クーポンはクエリパラメータから取得したものを優先する
      { coupon: defaultCoupon }
    )
  );

  const { mutateAsync: validateForm } = useSaveCheckoutProgress();
  const handleChangedEmail = useCallback(
    async (email: string) => {
      if (email && cart && cart.products.length > 0) {
        try {
          await validateForm({
            data: { email, products: cart.products.map(convertProductToNuxtStoreProduct) },
          });
        } catch (e) {
          htmlToast.error(getErrorMessages(e));
        }
      }
    },
    [cart, validateForm]
  );

  useOnce(() => {
    if (amazonPayEnable) scrollToFormTop();
  });

  const pathname = usePathname();
  const [resultReturnPath, reviewReturnPath] = useMemo(() => {
    const coupon = formValues.coupon;
    let resultReturnPath = pathname;
    let reviewReturnPath = pathname;
    if (coupon) {
      reviewReturnPath += `?code=${coupon}`;
      resultReturnPath += `?code=${coupon}`;
    }
    return [resultReturnPath, reviewReturnPath] as const;
  }, [formValues.coupon, pathname]);

  const handleConfirm = useCallback(
    async (formValues: CheckoutFormValues) => {
      startLoading();
      try {
        if (formValues.paymentData.paymentMethod === "amazon" && !amazonPayEnable) {
          // AmazonPayが指定されているが、AmazonPayが有効でない場合は、AmazonPayの設定を行う
          // reviewReturnPathにcoupon他のクエリパラメータが含まれている場合は、そのまま渡す
          const coupon = formValues.coupon;
          let reviewReturnPath = pathname;
          let resultReturnPath = `${pathname}?confirm=true`;
          if (coupon) {
            reviewReturnPath += `?code=${coupon}`;
            resultReturnPath += `&code=${coupon}`;
          }
          await setupAmazonPay({
            resultReturnPath,
            reviewReturnPath,
            isSubscription: false,
          });
          return;
        }

        if (await confirm(formValues, selectedProducts, products)) {
          setConfirming(true);
        } else {
          scrollToFormTop();
        }
      } finally {
        endLoading();
      }
      scrollToFormTop();
    },
    [
      amazonPayEnable,
      confirm,
      endLoading,
      pathname,
      products,
      scrollToFormTop,
      selectedProducts,
      startLoading,
    ]
  );

  const handleBack = useCallback(() => {
    setConfirming(false);
  }, []);

  const handleSubmit = useCallback(async () => {
    if (!cart) return;
    startLoading();
    scrollToFormTop();
    try {
      if (formValues.paymentData?.paymentMethod === "amazon" && !forceSubmit) {
        // AmazonPayのチェックアウトURLを取得してforceSubmit=trueの状態で戻ってくる
        const coupon = formValues.coupon;
        let reviewReturnPath = pathname;
        let resultReturnPath = `${pathname}?confirm=true`;
        if (coupon) {
          reviewReturnPath += `?code=${coupon}`;
          resultReturnPath += `&code=${coupon}`;
        }

        setFormDataForAmazonPay("amazon_pay_input_data", formValues);
        const { url } = await getAmazonCheckoutUrl({
          amazonCheckoutSessionId: formValues.paymentData.amazonCheckoutSessionId!,
          amount: String(cart.totalPrice),
          is_subscription: cart.isSubscription ?? false,
          review_return_path: reviewReturnPath,
          result_return_path: resultReturnPath,
        });
        if (url) {
          location.href = url;
        }
        return;
      }
      const result = await submit({ ...persistentForm, set_names: [`${variant}_${selectIndex}`] });
      if (!result) return;
      // 注文できたらログイン状態にしておく
      if (result.token) {
        setAccessToken(result.token);
      }

      if (!result.order) return;
      // 注文情報をローカルストレージに保存
      setOrder(result.order);

      // 完了ページへリダイレクト
      router.push(
        `/checkout/5?from=${pageFrom}&utm_content=${getUtmContent(pageFrom, variant, selectIndex)}`
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    } finally {
      endLoading();
    }
  }, [
    cart,
    endLoading,
    forceSubmit,
    formValues,
    pageFrom,
    pathname,
    persistentForm,
    router,
    scrollToFormTop,
    selectIndex,
    setAccessToken,
    setOrder,
    startLoading,
    submit,
    variant,
  ]);

  useOnce(() => {
    if (forceSubmit) {
      handleSubmit();
    }
  }, Boolean(cart));

  // AmazonPay
  // AmazonPayのチェックアウトデータの取得でエラーが発生した場合の処理
  useOnce(async () => {
    if (amazonPayCheckoutError) {
      alert(amazonPayCheckoutError);
      await fireAmazonPayAction(amazonPayCheckoutSessionId, "changeAddress");
      return;
    }
  });

  const handleClickAmazonPay = useCallback(() => {
    setFormDataForAmazonPay("amazon_pay_input_data", convertFormValuesToStorage(formValues));
  }, [formValues]);

  return (
    <>
      <LoadingOverlay isLoading={isLoading}>
        <ScrollAnchor id={FOOTER_BUTTON_TARGET_ID}>
          <OnePageCheckoutFormTitle isInvited={isInvited} coupon={defaultCoupon} />
          {!confirming ? (
            <>
              <SetSelector
                products={products}
                variant={variant}
                initialSelectIndex={selectIndex}
                initialSetProducts={selectedProducts}
                isInvited={isInvited}
                coupon={formValues.coupon}
                onChange={handleSelectedProductsChange}
                hideFreeSelect={hideFreeSelect}
              />
              {!amazonPayEnable && (
                <AmazonPayPanel
                  isSubscription
                  onClickPayButton={handleClickAmazonPay}
                  resultReturnPath={resultReturnPath}
                  reviewReturnPath={reviewReturnPath}
                />
              )}
              <CheckoutForm
                cart={cart}
                defaultValues={formValues}
                onChange={setFormValues}
                showBackButton={false}
                // Email、Passwordを常に入力可にするため、ログイン状態を考慮しない
                isLoggedIn={false}
                showLogin={false}
                showCoupon={!isInvited}
                onChangedEmail={handleChangedEmail}
                hiddenForm={false}
                confirmButtonVariants="yellow"
                onConfirm={handleConfirm}
              />
            </>
          ) : (
            <CheckoutConfirmation onClickBack={handleBack} onClickSubmit={handleSubmit} />
          )}
        </ScrollAnchor>
      </LoadingOverlay>
      {isSubmitting && (
        <FullScreenProgressBar
          percentage={submittingProgress}
          completeCallback={resetSubmittingStatus}
        />
      )}
    </>
  );
});
