/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * Payse API
 * How to use payse API
 * OpenAPI spec version: 3.0.1
 */
import { useMutation, useQuery, useSuspenseQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryClient,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from "@tanstack/react-query";
import type {
  CalcSubscriptionCartBody,
  Cart,
  CartCalcApiResponse,
  ChangeCard200,
  ChangeCardBodyOne,
  ChangeCardBodyTwo,
  CheckDuplicateCard200,
  CheckDuplicateCardBodyOne,
  CheckDuplicateCardBodyTwo,
  CheckHoldBackCoupon200,
  CheckHoldBackCouponParams,
  DeleteSubscriptionParams,
  GetCardLast4200,
  GetValidDates200,
  ServerErrorResponse,
  SubmitCard200,
  SubmitCard422,
  SubmitCardBodyOne,
  SubmitCardBodyTwo,
  Subscription,
  SubscriptionResponse,
  ValidateCoupon200,
  ValidateCouponBody,
} from ".././schemas";
import { customAxiosInstance } from "../../../configs/customAxiosInstance";
import type { ErrorType } from "../../../configs/customAxiosInstance";
import { customFormUrlEncodedFn } from "../../../configs/formUrlEncoded";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary get subscription data
 */
export const getSubscription = (
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<SubscriptionResponse>(
    { url: `/subscription`, method: "GET", signal },
    options
  );
};

export const getGetSubscriptionQueryKey = () => {
  return [`/subscription`] as const;
};

export const getGetSubscriptionQueryOptions = <
  TData = Awaited<ReturnType<typeof getSubscription>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSubscription>>, TError, TData>>;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSubscriptionQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSubscription>>> = ({ signal }) =>
    getSubscription(requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getSubscription>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetSubscriptionQueryResult = NonNullable<Awaited<ReturnType<typeof getSubscription>>>;
export type GetSubscriptionQueryError = ErrorType<unknown>;

/**
 * @summary get subscription data
 */
export const useGetSubscription = <
  TData = Awaited<ReturnType<typeof getSubscription>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSubscription>>, TError, TData>>;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSubscriptionQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary get subscription data
 */
export const prefetchGetSubscription = async <
  TData = Awaited<ReturnType<typeof getSubscription>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSubscription>>, TError, TData>>;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetSubscriptionQueryOptions(options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getGetSubscriptionSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getSubscription>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<Awaited<ReturnType<typeof getSubscription>>, TError, TData>
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSubscriptionQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSubscription>>> = ({ signal }) =>
    getSubscription(requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getSubscription>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetSubscriptionSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSubscription>>
>;
export type GetSubscriptionSuspenseQueryError = ErrorType<unknown>;

/**
 * @summary get subscription data
 */
export const useGetSubscriptionSuspense = <
  TData = Awaited<ReturnType<typeof getSubscription>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<Awaited<ReturnType<typeof getSubscription>>, TError, TData>
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSubscriptionSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary get subscription data
 */
export const prefetchGetSubscriptionSuspense = async <
  TData = Awaited<ReturnType<typeof getSubscription>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getSubscription>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetSubscriptionSuspenseQueryOptions(options);

  await queryClient.prefetchSuspenseQuery(queryOptions);

  return queryClient;
};

/**
 * @summary update subscription
 */
export const updateSubscription = (
  subscription: Subscription,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    {
      url: `/subscription`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: subscription,
    },
    options
  );
};

export const getUpdateSubscriptionMutationOptions = <
  TError = ErrorType<ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateSubscription>>,
    TError,
    { data: Subscription },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateSubscription>>,
  TError,
  { data: Subscription },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateSubscription>>,
    { data: Subscription }
  > = (props) => {
    const { data } = props ?? {};

    return updateSubscription(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateSubscriptionMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateSubscription>>
>;
export type UpdateSubscriptionMutationBody = Subscription;
export type UpdateSubscriptionMutationError = ErrorType<ServerErrorResponse>;

/**
 * @summary update subscription
 */
export const useUpdateSubscription = <
  TError = ErrorType<ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateSubscription>>,
    TError,
    { data: Subscription },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const mutationOptions = getUpdateSubscriptionMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary delete subscription
 */
export const deleteSubscription = (
  params: DeleteSubscriptionParams,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>({ url: `/subscription`, method: "DELETE", params }, options);
};

export const getDeleteSubscriptionMutationOptions = <
  TError = ErrorType<ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteSubscription>>,
    TError,
    { params: DeleteSubscriptionParams },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteSubscription>>,
  TError,
  { params: DeleteSubscriptionParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteSubscription>>,
    { params: DeleteSubscriptionParams }
  > = (props) => {
    const { params } = props ?? {};

    return deleteSubscription(params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteSubscriptionMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteSubscription>>
>;

export type DeleteSubscriptionMutationError = ErrorType<ServerErrorResponse>;

/**
 * @summary delete subscription
 */
export const useDeleteSubscription = <
  TError = ErrorType<ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteSubscription>>,
    TError,
    { params: DeleteSubscriptionParams },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const mutationOptions = getDeleteSubscriptionMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary validate coupon code
 */
export const validateCoupon = (
  validateCouponBody: ValidateCouponBody,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  const formUrlEncoded = customFormUrlEncodedFn(validateCouponBody);
  return customAxiosInstance<ValidateCoupon200>(
    {
      url: `/subscription/coupon/validate`,
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: formUrlEncoded,
    },
    options
  );
};

export const getValidateCouponMutationOptions = <
  TError = ErrorType<ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof validateCoupon>>,
    TError,
    { data: ValidateCouponBody },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof validateCoupon>>,
  TError,
  { data: ValidateCouponBody },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof validateCoupon>>,
    { data: ValidateCouponBody }
  > = (props) => {
    const { data } = props ?? {};

    return validateCoupon(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ValidateCouponMutationResult = NonNullable<Awaited<ReturnType<typeof validateCoupon>>>;
export type ValidateCouponMutationBody = ValidateCouponBody;
export type ValidateCouponMutationError = ErrorType<ServerErrorResponse>;

/**
 * @summary validate coupon code
 */
export const useValidateCoupon = <
  TError = ErrorType<ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof validateCoupon>>,
    TError,
    { data: ValidateCouponBody },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const mutationOptions = getValidateCouponMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary update subscription
 */
export const updateSubscriptionBillingInfo = (
  subscription: Subscription,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    {
      url: `/subscription/billing_info`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: subscription,
    },
    options
  );
};

export const getUpdateSubscriptionBillingInfoMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateSubscriptionBillingInfo>>,
    TError,
    { data: Subscription },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateSubscriptionBillingInfo>>,
  TError,
  { data: Subscription },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateSubscriptionBillingInfo>>,
    { data: Subscription }
  > = (props) => {
    const { data } = props ?? {};

    return updateSubscriptionBillingInfo(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateSubscriptionBillingInfoMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateSubscriptionBillingInfo>>
>;
export type UpdateSubscriptionBillingInfoMutationBody = Subscription;
export type UpdateSubscriptionBillingInfoMutationError = ErrorType<unknown>;

/**
 * @summary update subscription
 */
export const useUpdateSubscriptionBillingInfo = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateSubscriptionBillingInfo>>,
    TError,
    { data: Subscription },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const mutationOptions = getUpdateSubscriptionBillingInfoMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary update subscription coupon
 */
export const updateSubscriptionCoupon = (
  subscription: Subscription,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<Cart>(
    {
      url: `/subscription/coupon`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: subscription,
    },
    options
  );
};

export const getUpdateSubscriptionCouponMutationOptions = <
  TError = ErrorType<ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateSubscriptionCoupon>>,
    TError,
    { data: Subscription },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateSubscriptionCoupon>>,
  TError,
  { data: Subscription },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateSubscriptionCoupon>>,
    { data: Subscription }
  > = (props) => {
    const { data } = props ?? {};

    return updateSubscriptionCoupon(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateSubscriptionCouponMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateSubscriptionCoupon>>
>;
export type UpdateSubscriptionCouponMutationBody = Subscription;
export type UpdateSubscriptionCouponMutationError = ErrorType<ServerErrorResponse>;

/**
 * @summary update subscription coupon
 */
export const useUpdateSubscriptionCoupon = <
  TError = ErrorType<ServerErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateSubscriptionCoupon>>,
    TError,
    { data: Subscription },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const mutationOptions = getUpdateSubscriptionCouponMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary update subscription delivery_date
 */
export const updateSubscriptionDeliveryDate = (
  subscription: Subscription,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    {
      url: `/subscription/delivery_date`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: subscription,
    },
    options
  );
};

export const getUpdateSubscriptionDeliveryDateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateSubscriptionDeliveryDate>>,
    TError,
    { data: Subscription },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateSubscriptionDeliveryDate>>,
  TError,
  { data: Subscription },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateSubscriptionDeliveryDate>>,
    { data: Subscription }
  > = (props) => {
    const { data } = props ?? {};

    return updateSubscriptionDeliveryDate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateSubscriptionDeliveryDateMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateSubscriptionDeliveryDate>>
>;
export type UpdateSubscriptionDeliveryDateMutationBody = Subscription;
export type UpdateSubscriptionDeliveryDateMutationError = ErrorType<unknown>;

/**
 * @summary update subscription delivery_date
 */
export const useUpdateSubscriptionDeliveryDate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateSubscriptionDeliveryDate>>,
    TError,
    { data: Subscription },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const mutationOptions = getUpdateSubscriptionDeliveryDateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary update subscription point
 */
export const updateSubscriptionPoint = (
  subscription: Subscription,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<Cart>(
    {
      url: `/subscription/point`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: subscription,
    },
    options
  );
};

export const getUpdateSubscriptionPointMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateSubscriptionPoint>>,
    TError,
    { data: Subscription },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateSubscriptionPoint>>,
  TError,
  { data: Subscription },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateSubscriptionPoint>>,
    { data: Subscription }
  > = (props) => {
    const { data } = props ?? {};

    return updateSubscriptionPoint(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateSubscriptionPointMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateSubscriptionPoint>>
>;
export type UpdateSubscriptionPointMutationBody = Subscription;
export type UpdateSubscriptionPointMutationError = ErrorType<unknown>;

/**
 * @summary update subscription point
 */
export const useUpdateSubscriptionPoint = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateSubscriptionPoint>>,
    TError,
    { data: Subscription },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const mutationOptions = getUpdateSubscriptionPointMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary get card_last4 data
 */
export const getCardLast4 = (
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<GetCardLast4200>(
    { url: `/card_last4`, method: "GET", signal },
    options
  );
};

export const getGetCardLast4QueryKey = () => {
  return [`/card_last4`] as const;
};

export const getGetCardLast4QueryOptions = <
  TData = Awaited<ReturnType<typeof getCardLast4>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getCardLast4>>, TError, TData>>;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCardLast4QueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCardLast4>>> = ({ signal }) =>
    getCardLast4(requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getCardLast4>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCardLast4QueryResult = NonNullable<Awaited<ReturnType<typeof getCardLast4>>>;
export type GetCardLast4QueryError = ErrorType<unknown>;

/**
 * @summary get card_last4 data
 */
export const useGetCardLast4 = <
  TData = Awaited<ReturnType<typeof getCardLast4>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getCardLast4>>, TError, TData>>;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCardLast4QueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary get card_last4 data
 */
export const prefetchGetCardLast4 = async <
  TData = Awaited<ReturnType<typeof getCardLast4>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getCardLast4>>, TError, TData>>;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetCardLast4QueryOptions(options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getGetCardLast4SuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getCardLast4>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCardLast4>>, TError, TData>>;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCardLast4QueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCardLast4>>> = ({ signal }) =>
    getCardLast4(requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getCardLast4>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCardLast4SuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getCardLast4>>>;
export type GetCardLast4SuspenseQueryError = ErrorType<unknown>;

/**
 * @summary get card_last4 data
 */
export const useGetCardLast4Suspense = <
  TData = Awaited<ReturnType<typeof getCardLast4>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCardLast4>>, TError, TData>>;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCardLast4SuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary get card_last4 data
 */
export const prefetchGetCardLast4Suspense = async <
  TData = Awaited<ReturnType<typeof getCardLast4>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCardLast4>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetCardLast4SuspenseQueryOptions(options);

  await queryClient.prefetchSuspenseQuery(queryOptions);

  return queryClient;
};

/**
 * @summary calc subscription cart
 */
export const calcSubscriptionCart = (
  calcSubscriptionCartBody: CalcSubscriptionCartBody,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  const formUrlEncoded = customFormUrlEncodedFn(calcSubscriptionCartBody);
  return customAxiosInstance<CartCalcApiResponse>(
    {
      url: `/subscription/cart`,
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: formUrlEncoded,
    },
    options
  );
};

export const getCalcSubscriptionCartMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof calcSubscriptionCart>>,
    TError,
    { data: CalcSubscriptionCartBody },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof calcSubscriptionCart>>,
  TError,
  { data: CalcSubscriptionCartBody },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof calcSubscriptionCart>>,
    { data: CalcSubscriptionCartBody }
  > = (props) => {
    const { data } = props ?? {};

    return calcSubscriptionCart(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CalcSubscriptionCartMutationResult = NonNullable<
  Awaited<ReturnType<typeof calcSubscriptionCart>>
>;
export type CalcSubscriptionCartMutationBody = CalcSubscriptionCartBody;
export type CalcSubscriptionCartMutationError = ErrorType<unknown>;

/**
 * @summary calc subscription cart
 */
export const useCalcSubscriptionCart = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof calcSubscriptionCart>>,
    TError,
    { data: CalcSubscriptionCartBody },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const mutationOptions = getCalcSubscriptionCartMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary get valid dates
 */
export const getValidDates = (
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<GetValidDates200>(
    { url: `/valid_dates`, method: "GET", signal },
    options
  );
};

export const getGetValidDatesQueryKey = () => {
  return [`/valid_dates`] as const;
};

export const getGetValidDatesQueryOptions = <
  TData = Awaited<ReturnType<typeof getValidDates>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getValidDates>>, TError, TData>>;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetValidDatesQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getValidDates>>> = ({ signal }) =>
    getValidDates(requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getValidDates>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetValidDatesQueryResult = NonNullable<Awaited<ReturnType<typeof getValidDates>>>;
export type GetValidDatesQueryError = ErrorType<unknown>;

/**
 * @summary get valid dates
 */
export const useGetValidDates = <
  TData = Awaited<ReturnType<typeof getValidDates>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getValidDates>>, TError, TData>>;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetValidDatesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary get valid dates
 */
export const prefetchGetValidDates = async <
  TData = Awaited<ReturnType<typeof getValidDates>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getValidDates>>, TError, TData>>;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetValidDatesQueryOptions(options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getGetValidDatesSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getValidDates>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<Awaited<ReturnType<typeof getValidDates>>, TError, TData>
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetValidDatesQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getValidDates>>> = ({ signal }) =>
    getValidDates(requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getValidDates>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetValidDatesSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getValidDates>>
>;
export type GetValidDatesSuspenseQueryError = ErrorType<unknown>;

/**
 * @summary get valid dates
 */
export const useGetValidDatesSuspense = <
  TData = Awaited<ReturnType<typeof getValidDates>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<Awaited<ReturnType<typeof getValidDates>>, TError, TData>
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetValidDatesSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary get valid dates
 */
export const prefetchGetValidDatesSuspense = async <
  TData = Awaited<ReturnType<typeof getValidDates>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getValidDates>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetValidDatesSuspenseQueryOptions(options);

  await queryClient.prefetchSuspenseQuery(queryOptions);

  return queryClient;
};

/**
 * @summary check hold back coupon
 */
export const checkHoldBackCoupon = (
  params: CheckHoldBackCouponParams,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<CheckHoldBackCoupon200>(
    { url: `/subscription/check_hold_back_coupon`, method: "GET", params, signal },
    options
  );
};

export const getCheckHoldBackCouponQueryKey = (params: CheckHoldBackCouponParams) => {
  return [`/subscription/check_hold_back_coupon`, ...(params ? [params] : [])] as const;
};

export const getCheckHoldBackCouponQueryOptions = <
  TData = Awaited<ReturnType<typeof checkHoldBackCoupon>>,
  TError = ErrorType<unknown>,
>(
  params: CheckHoldBackCouponParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof checkHoldBackCoupon>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getCheckHoldBackCouponQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof checkHoldBackCoupon>>> = ({ signal }) =>
    checkHoldBackCoupon(params, requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof checkHoldBackCoupon>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CheckHoldBackCouponQueryResult = NonNullable<
  Awaited<ReturnType<typeof checkHoldBackCoupon>>
>;
export type CheckHoldBackCouponQueryError = ErrorType<unknown>;

/**
 * @summary check hold back coupon
 */
export const useCheckHoldBackCoupon = <
  TData = Awaited<ReturnType<typeof checkHoldBackCoupon>>,
  TError = ErrorType<unknown>,
>(
  params: CheckHoldBackCouponParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof checkHoldBackCoupon>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCheckHoldBackCouponQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary check hold back coupon
 */
export const prefetchCheckHoldBackCoupon = async <
  TData = Awaited<ReturnType<typeof checkHoldBackCoupon>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  params: CheckHoldBackCouponParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof checkHoldBackCoupon>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getCheckHoldBackCouponQueryOptions(params, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getCheckHoldBackCouponSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof checkHoldBackCoupon>>,
  TError = ErrorType<unknown>,
>(
  params: CheckHoldBackCouponParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof checkHoldBackCoupon>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getCheckHoldBackCouponQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof checkHoldBackCoupon>>> = ({ signal }) =>
    checkHoldBackCoupon(params, requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof checkHoldBackCoupon>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CheckHoldBackCouponSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof checkHoldBackCoupon>>
>;
export type CheckHoldBackCouponSuspenseQueryError = ErrorType<unknown>;

/**
 * @summary check hold back coupon
 */
export const useCheckHoldBackCouponSuspense = <
  TData = Awaited<ReturnType<typeof checkHoldBackCoupon>>,
  TError = ErrorType<unknown>,
>(
  params: CheckHoldBackCouponParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof checkHoldBackCoupon>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCheckHoldBackCouponSuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary check hold back coupon
 */
export const prefetchCheckHoldBackCouponSuspense = async <
  TData = Awaited<ReturnType<typeof checkHoldBackCoupon>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  params: CheckHoldBackCouponParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof checkHoldBackCoupon>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getCheckHoldBackCouponSuspenseQueryOptions(params, options);

  await queryClient.prefetchSuspenseQuery(queryOptions);

  return queryClient;
};

/**
 * @summary submit card
 */
export const submitCard = (
  submitCardBody: SubmitCardBodyOne | SubmitCardBodyTwo,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<SubmitCard200>(
    { url: `/subscription/submit_card`, method: "PUT", data: submitCardBody },
    options
  );
};

export const getSubmitCardMutationOptions = <
  TError = ErrorType<SubmitCard422>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof submitCard>>,
    TError,
    { data: SubmitCardBodyOne | SubmitCardBodyTwo },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof submitCard>>,
  TError,
  { data: SubmitCardBodyOne | SubmitCardBodyTwo },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof submitCard>>,
    { data: SubmitCardBodyOne | SubmitCardBodyTwo }
  > = (props) => {
    const { data } = props ?? {};

    return submitCard(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SubmitCardMutationResult = NonNullable<Awaited<ReturnType<typeof submitCard>>>;
export type SubmitCardMutationBody = SubmitCardBodyOne | SubmitCardBodyTwo;
export type SubmitCardMutationError = ErrorType<SubmitCard422>;

/**
 * @summary submit card
 */
export const useSubmitCard = <TError = ErrorType<SubmitCard422>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof submitCard>>,
    TError,
    { data: SubmitCardBodyOne | SubmitCardBodyTwo },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const mutationOptions = getSubmitCardMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary change card
 */
export const changeCard = (
  changeCardBody: ChangeCardBodyOne | ChangeCardBodyTwo,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<ChangeCard200>(
    { url: `/subscription/change_card`, method: "PUT", data: changeCardBody },
    options
  );
};

export const getChangeCardMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof changeCard>>,
    TError,
    { data: ChangeCardBodyOne | ChangeCardBodyTwo },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof changeCard>>,
  TError,
  { data: ChangeCardBodyOne | ChangeCardBodyTwo },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof changeCard>>,
    { data: ChangeCardBodyOne | ChangeCardBodyTwo }
  > = (props) => {
    const { data } = props ?? {};

    return changeCard(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ChangeCardMutationResult = NonNullable<Awaited<ReturnType<typeof changeCard>>>;
export type ChangeCardMutationBody = ChangeCardBodyOne | ChangeCardBodyTwo;
export type ChangeCardMutationError = ErrorType<unknown>;

/**
 * @summary change card
 */
export const useChangeCard = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof changeCard>>,
    TError,
    { data: ChangeCardBodyOne | ChangeCardBodyTwo },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const mutationOptions = getChangeCardMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary check duplicate card
 */
export const checkDuplicateCard = (
  checkDuplicateCardBody: CheckDuplicateCardBodyOne | CheckDuplicateCardBodyTwo,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<CheckDuplicateCard200>(
    { url: `/subscription/check_duplicate_card`, method: "POST", data: checkDuplicateCardBody },
    options
  );
};

export const getCheckDuplicateCardMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof checkDuplicateCard>>,
    TError,
    { data: CheckDuplicateCardBodyOne | CheckDuplicateCardBodyTwo },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof checkDuplicateCard>>,
  TError,
  { data: CheckDuplicateCardBodyOne | CheckDuplicateCardBodyTwo },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof checkDuplicateCard>>,
    { data: CheckDuplicateCardBodyOne | CheckDuplicateCardBodyTwo }
  > = (props) => {
    const { data } = props ?? {};

    return checkDuplicateCard(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CheckDuplicateCardMutationResult = NonNullable<
  Awaited<ReturnType<typeof checkDuplicateCard>>
>;
export type CheckDuplicateCardMutationBody = CheckDuplicateCardBodyOne | CheckDuplicateCardBodyTwo;
export type CheckDuplicateCardMutationError = ErrorType<unknown>;

/**
 * @summary check duplicate card
 */
export const useCheckDuplicateCard = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof checkDuplicateCard>>,
    TError,
    { data: CheckDuplicateCardBodyOne | CheckDuplicateCardBodyTwo },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const mutationOptions = getCheckDuplicateCardMutationOptions(options);

  return useMutation(mutationOptions);
};
