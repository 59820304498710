import { useMemo } from "react";

import { useGetPointMile } from "@/generated/open-api/customer/customer";
import { convertObjToCamelCase } from "@/utils/converters";

export const useParsedGetPointMile = () => {
  const { data, ...rest } = useGetPointMile();

  const pointMile = useMemo(() => {
    if (!data) return;
    return convertObjToCamelCase(data);
  }, [data]);

  return { data: pointMile, ...rest };
};
