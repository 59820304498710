export const PRODUCT_SALE_PARAMETER = {
  firstDiscountRate: 0.8,
  secondDiscountRate: 0.9,
  shippingFeeNormal: 500,
  shippingFeeFrozen: 900,
  // 招待割引金額
  inviteDiscount: -2000,
  // 配送できる最低限の金額--常温
  minDeliveryAmountNormal: 2600,
  // 配送できる最低限の金額--冷凍
  minDeliveryAmountFrozen: 2600,
};

export const SYS_PARAMETER = {
  unsubscribePresentCouponId: "KYKSTP",
};

export const FEATURE_FLAG = {
  isFreeShippingCampaignActive: false,
};

export const OKIHAI_MAX_PRICE = 150000;
