import { useCallback, useMemo } from "react";

import { QueryObserverOptions, QueryOptions, useQuery } from "@tanstack/react-query";

import { calcSubscriptionCart } from "@/generated/axios-functions/payseAPI";
import { CalcSubscriptionCartBody } from "@/generated/open-api/schemas";
import { convertApiCartToCart } from "@/models/cart/converters";
import { CartModel } from "@/models/cart/type";
import { SnakeToCamelCaseNested } from "@/utils";
import { convertObjToSnakeCase } from "@/utils/converters";
import { objectKeys } from "@/utils/object";

type ParsedSubscriptionCartParams = SnakeToCamelCaseNested<CalcSubscriptionCartBody>;

export async function getParsedSubscriptionCart(params: ParsedSubscriptionCartParams) {
  const res = (await calcSubscriptionCart(convertObjToSnakeCase(params))).cart;
  if (!res) return undefined;
  return convertApiCartToCart(res);
}

type UseParsedSubscriptionCartOptions = Omit<QueryOptions, "queryKey" | "queryFn"> &
  Omit<QueryObserverOptions, "queryKey" | "queryFn">;
/**
 * HTTPメソッドがPOSTでSuspenseのhooksが生成されないため自作する
 */
export function useParsedSubscriptionCart(
  params: ParsedSubscriptionCartParams,
  options?: UseParsedSubscriptionCartOptions
) {
  const queryKey = useMemo(() => getParsedSubscriptionCartKey(params), [params]);
  const queryFn = useCallback(() => getParsedSubscriptionCart(params), [params]);
  const { data, ...rest } = useQuery({ queryKey, queryFn, ...options });
  const subscriptionCart = useMemo(() => data, [data]);
  return { data: subscriptionCart as CartModel | undefined, ...rest };
}

export function getParsedSubscriptionCartKey(params: ParsedSubscriptionCartParams) {
  const sortedKeys = objectKeys(params).sort();
  // ソートされたキーを使用して新しいURLSearchParamsを生成
  const sortedParams = new URLSearchParams();
  sortedKeys.forEach((key) => {
    sortedParams.append(key, JSON.stringify(params[key]));
  });
  return [`/subscription/cart`, sortedParams.toString()];
}
