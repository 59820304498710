import { StripeCardNumberElement } from "@stripe/stripe-js";
import { z } from "zod";

import { DeliveryReceiveOption, DeliveryTimeZone } from "@/models/delivery/consts";
import { ConvenienceStore, PaymentMethod } from "@/models/payment/consts";
import { enumToZodEnum } from "@/utils/zod";

export const AddressSchema = z.object({
  lastName: z.string().min(1),
  firstName: z.string().min(1),
  zip: z.string().min(7),
  province: z.string().min(1),
  city: z.string().min(1),
  addressLine1: z.string().min(1),
  addressLine2: z.string().default(""),
  phone: z.string().min(10),
});

export type AddressValues = z.infer<typeof AddressSchema>;

export const PaymentDataSchema = z.object({
  paymentMethod: z.enum(enumToZodEnum(PaymentMethod)),
  amazonCheckoutSessionId: z.string().optional(),
  store: z.enum(enumToZodEnum(ConvenienceStore)),
  bankFirstName: z.string().optional(),
  bankLastName: z.string().optional(),
  bankLastNameKana: z.string().optional(),
  bankFirstNameKana: z.string().optional(),
  last4: z.string().optional(),
  stripeToken: z.string().optional(),
  creditCard: z.custom<StripeCardNumberElement>(() => true).optional(),
});

export type PaymentDataValues = z.infer<typeof PaymentDataSchema>;

export const UserCredentialsSchema = z.object({
  email: z.string().email(),
  password: z.string().min(8),
  acceptsMarketing: z.boolean().default(true), // こいつはここにいると都合がいいのでここにいる
});

export type UserCredentialsValues = z.infer<typeof UserCredentialsSchema>;

export const DeliveryOptionsSchema = z.object({
  isSpecifiedDate: z.boolean(),
  deliveryDate: z.date(),
  deliveryTimezone: z.enum(enumToZodEnum(DeliveryTimeZone)),
  deliveryReceiveOption: z.enum(enumToZodEnum(DeliveryReceiveOption)),
});

export type DeliveryOptionsValues = z.infer<typeof DeliveryOptionsSchema>;

// export const ProductSchema = z.object({
//   variantId: z.string(),
//   quantity: z.number().int().positive(),
// });

export const CheckoutFormSchema = z.object({
  credentials: UserCredentialsSchema,
  paymentData: PaymentDataSchema,
  deliveryOptions: DeliveryOptionsSchema,
  shippingAddress: AddressSchema,
  billingAddressSelect: z.boolean(),
  billingAddress: AddressSchema,
  coupon: z.string().optional(),
  // products: z.array(ProductSchema).default([]),
});

export type CheckoutFormValues = z.infer<typeof CheckoutFormSchema>;
