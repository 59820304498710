import { useMemo } from "react";

import clsx from "clsx";

import { ProductListItem } from "@/components/domains/cart/ProductListItem";
import { CartModel } from "@/models/cart/type";
import { ProductModel, ProductTemperature } from "@/models/product/type";
import { formatCurrency } from "@/utils/currency";

import styles from "./ProductList.module.scss";

export interface ProductListProps {
  cart: CartModel;
  products: ProductModel[];
  quantityMap: Map<number, number>;
  changeQuantity: (quantity: number, variantId?: number) => void;
  temperature: ProductTemperature;
}

export function ProductList({
  cart,
  products,
  quantityMap,
  changeQuantity,
  temperature,
}: ProductListProps): React.ReactNode {
  const existAll = useMemo(
    () => !!cart?.normalProductTotalPrice && !!cart?.freezeProductTotalPrice,
    [cart]
  );

  return (
    <section
      className={clsx(
        "mg__bottom__m",
        styles.container,
        temperature === "normal" ? styles.normalContainer : styles.freezeContainer
      )}
    >
      {existAll && (
        <p
          className={clsx(
            "text__m text__bold text__white",
            styles.title,
            temperature === "normal" ? styles.normal : styles.freeze
          )}
        >
          {temperature === "normal" ? "常温配送" : "冷凍配送"}
        </p>
      )}

      {products.map((product) => (
        <ProductListItem
          product={product}
          quantity={quantityMap.get(product.variantId ?? 0) ?? 0}
          isSubscription={!!cart?.isSubscription}
          onChange={(event) => changeQuantity(Number(event.target.value), product.variantId)}
          onClickDeletion={() => changeQuantity(0, product.variantId)}
          key={product.variantId}
        />
      ))}

      {/*バリデーション*/}
      <div className={styles.validation}>
        {(temperature === "normal" ? !cart?.validPurchase : !cart?.validFreezePurchase) && (
          <div className={clsx("mg__top__m", styles.error)}>
            <p className="text__m text__center text__white">
              合計金額2,600円以上から
              <br />
              ご購入いただけます。
            </p>
          </div>
        )}
      </div>

      {/*小計*/}
      {existAll && (
        <div className={clsx("mg__top__s", styles.subtotal)}>
          <div className="text__left">
            <span className="text__m">小計：</span>
          </div>
          <span
            className={clsx("text__right text__l text__bold", cart?.isSubscription && "text__red")}
          >
            {formatCurrency(
              (temperature === "normal"
                ? cart?.normalProductTotalPrice
                : cart?.freezeProductTotalPrice) ?? 0
            )}
          </span>
        </div>
      )}
    </section>
  );
}
