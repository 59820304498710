interface Product {
  id: string;
  src: string;
  alt: string;
  aspectRatio: string;
  detailSrc: string;
  detailAspectRatio: string;
}

export const lpProducts: Product[] = [
  {
    id: "chocolate",
    src: "https://asset.basefood.co.jp/lp/common/product_chocolate_v1_12.png",
    alt: "Chocolate",
    aspectRatio: "1005 / 376",
    detailSrc: "https://asset.basefood.co.jp/images/pkg/pkg_bb_chocolate_v1_12_ura.png",
    detailAspectRatio: "245 / 202",
  },
  {
    id: "maple",
    src: "https://asset.basefood.co.jp/lp/common/product_maple_v1_6.png",
    alt: "Maple",
    aspectRatio: "1340 / 501",
    detailSrc: "https://asset.basefood.co.jp/images/pkg/pkg_bb_maple_v1_6_ura.png",
    detailAspectRatio: "245 / 202",
  },
  {
    id: "cinnamon",
    src: "https://asset.basefood.co.jp/lp/common/product_cinnamon_v1_6.png",
    alt: "Cinnamon",
    aspectRatio: "1340 / 501",
    detailSrc: "https://asset.basefood.co.jp/images/pkg/pkg_bb_cinnamon_v1_6_ura.png",
    detailAspectRatio: "245 / 202",
  },
  {
    id: "koshian",
    src: "https://asset.basefood.co.jp/lp/common/product_koshian_v1_1.png",
    alt: "Koshian",
    aspectRatio: "1340 / 501",
    detailSrc: "https://asset.basefood.co.jp/images/pkg/pkg_bb_koshian_v1_1_ura.png",
    detailAspectRatio: "245 / 202",
  },
  {
    id: "sliceRaisin",
    src: "https://asset.basefood.co.jp/lp/common/product_slice_raisin_v1_3.png",
    alt: "Raisin Slice",
    aspectRatio: "1005 / 376",
    detailSrc: "https://asset.basefood.co.jp/images/pkg/pkg_bb_slice_raisin_v1_3_ura.png",
    detailAspectRatio: "980 / 995",
  },
  {
    id: "curry",
    src: "https://asset.basefood.co.jp/lp/common/product_curry_v1_6.png",
    alt: "Curry",
    aspectRatio: "1340 / 501",
    detailSrc: "https://asset.basefood.co.jp/images/pkg/pkg_bb_curry_v1_6_ura.png",
    detailAspectRatio: "980 / 807",
  },
  {
    id: "rich",
    src: "https://asset.basefood.co.jp/lp/common/product_rich_v1_4.png",
    alt: "Rich",
    aspectRatio: "1340 / 501",
    detailSrc: "https://asset.basefood.co.jp/images/pkg/pkg_bb_rich_v1_4_ura.png",
    detailAspectRatio: "980 / 817",
  },
  {
    id: "plain",
    src: "https://asset.basefood.co.jp/lp/common/product_plain_v3_11.png",
    alt: "Plain",
    aspectRatio: "1340 / 501",
    detailSrc: "https://asset.basefood.co.jp/images/pkg/pkg_bb_plain_v3_11_ura.png",
    detailAspectRatio: "979 / 807",
  },
  {
    id: "slicePlain",
    src: "https://asset.basefood.co.jp/lp/common/product_slice_plain_v1_5.png",
    alt: "Plain Slice",
    aspectRatio: "1005 / 376",
    detailSrc: "https://asset.basefood.co.jp/images/pkg/pkg_bb_slice_plain_v1_5_ura.png",
    detailAspectRatio: "980 / 993",
  },
];
