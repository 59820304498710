export const sweetBreadCategoryProductNames = [
  "basebread_chocolate",
  "basebread_maple",
  "basebread_cinnamon",
  "basebread_koshian",
] as const;

export const mealBreadCategoryProductNames = [
  "basebread_slice_plain",
  "basebread_slice_raisin",
  "basebread_plain",
  "basebread_rich",
] as const;

export const savoryBreadCategoryProductNames = ["basebread_curry"] as const;

export const breadCategoryProductNames = [
  ...sweetBreadCategoryProductNames,
  ...mealBreadCategoryProductNames,
  ...savoryBreadCategoryProductNames,
] as const;

export const pancakeCategoryProductNames = ["basepancake_pancakemix"] as const;

export const pastaCategoryProductNames = ["basepasta_asian", "basepasta_fettochine"] as const;
export const cookieCategoryProductNames = [
  "basecookies_cocoa",
  "basecookies_earlgrey",
  "basecookies_matcha",
  "basecookies_coconut",
  "basecookies_sweetpotato",
  "basecookies_pepper",
  "basecookies_cheese",
] as const;
export const setCategoryProductNames = ["1", "2", "3", "4", "5"] as const;

export const sauceCategoryProductNames = ["sauce_set"] as const;

export const deliPastaCategoryProductNames = [
  "basepasta_bolognese",
  "basepasta_tarako",
  "basepasta_creamymushroom",
] as const;

export const yakisobaCategoryProductNames = [
  "basepasta_sauceyakisoba",
  "basepasta_umakaramazesoba",
] as const;

export const normalTemperatureProductsNames = [
  ...breadCategoryProductNames,
  ...pastaCategoryProductNames,
  ...cookieCategoryProductNames,
  ...sauceCategoryProductNames,
  ...pancakeCategoryProductNames,
  ...yakisobaCategoryProductNames,
] as const;

export const frozenTemperatureProductsNames = [...deliPastaCategoryProductNames] as const;
