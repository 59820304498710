"use client";

import React from "react";

import clsx from "clsx";

import { Image } from "@/components/displays";

import styles from "./SetDetail.module.scss";

interface Props {
  bags: 14 | 16 | 32 | 54;
  lp?: "gothic" | "maru" | "form";
}

const base = "https://asset.basefood.co.jp/lp/common";
const imageMap = {
  gothic: {
    14: `${base}/0626_set14_gothic.jpg`,
    16: `${base}/0626_set16_gothic.jpg`,
    32: `${base}/0626_set32_gothic.jpg`,
    54: `${base}/0626_set54_gothic.jpg`,
  },
  maru: {
    14: `${base}/0626_set14_maru.jpg`,
    16: `${base}/0626_set16_gothic.jpg`,
    32: `${base}/0626_set32_maru.jpg`,
    54: `${base}/0626_set54_maru.jpg`,
  },
  form: {
    14: `${base}/lp_set_content_14_0626.png`,
    16: `${base}/0626_set16_gothic.jpg`,
    32: `${base}/lp_set_content_32_0626.png`,
    54: `${base}/lp_set_content_54_0626.png`,
  },
};

export function SetDetail({ bags, lp = "gothic" }: Props): React.ReactNode {
  return (
    <Image
      src={imageMap[lp][bags]}
      alt={`${bags}袋セット`}
      aspectRatio="750 / 1349"
      containerClassName={clsx(styles.image)}
    />
  );
}
