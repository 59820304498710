import clsx from "clsx";

import styles from "./Row.module.scss";

export type RowProps = React.ComponentProps<"div">;

export function Row(props: RowProps): React.ReactNode {
  const { className, ...rest } = props;
  return <div className={clsx(styles.root, className)} {...rest} />;
}
