import { useCallback, useEffect, useMemo } from "react";

import { keepPreviousData } from "@tanstack/react-query";
import { isAxiosError } from "axios";
import { format } from "date-fns";
import { DeepPartial } from "react-hook-form";

import { htmlToast } from "@/components";
import { SelectedProducts } from "@/components/domains/lp/OnePageCheckoutForm/SetSelector/types";
import { LPRecommendations, LPRecommendationsVariant } from "@/components/domains/lp/settings";
import { createOrderPost, validateCart } from "@/generated/axios-functions/payseAPI";
import { Address, ValidateCartBody } from "@/generated/open-api/schemas";
import { convertAddressToApiAddress } from "@/models/customer/converters";
// TODO: 型の類を別ファイルに切り出す
import { DeliveryReceiveOption, DeliveryTimeZone } from "@/models/delivery/consts";
import { ConvenienceStore } from "@/models/payment/consts";
import { convertProductToNuxtStoreProduct } from "@/models/product/converters";
import { ProductModel } from "@/models/product/type";
import { useGetParsedCart } from "@/queries";
import { setClientCartCart, useClientCartForm } from "@/storage";
import { NuxtStoreCart } from "@/storage/types";
import { pushGtmEvent } from "@/utils/gtm";
import { objectEntries, objectValues } from "@/utils/object";

import { CheckoutFormValues } from "../../checkout-form";
import { useLPStore } from "../store";

/**
 * フォームの状態管理と、サブミット処理を管理するカスタムフック
 * クライアントサイドでのみ利用可能
 * @returns
 */
export const useForm = () => {
  const { form: persistentForm, setForm: setPersistentForm } = useClientCartForm();
  const form = useMemo(() => convertNuxtStoreFormToForm(persistentForm ?? {}), [persistentForm]);

  const { selectIndex, setIsSubmitting, setSubmittingProgress, resetSubmittingStatus } =
    useLPStore();

  const confirm = useCallback(
    async (
      formValues: CheckoutFormValues,
      selectedProducts: SelectedProducts,
      products: ProductModel[]
    ) => {
      if (formValues.paymentData.paymentMethod === "credit") {
        if (!formValues.paymentData.last4 || !formValues.paymentData.stripeToken) {
          htmlToast.error("カード情報を入力してください");
          return false;
        }
      }
      const newFormValues = convertFormValueToNuxtStoreForm(
        formValues,
        selectedProducts,
        products,
        selectIndex
      );

      try {
        // TODO: checkoutページと共通化
        const body: ValidateCartBody = {
          accepts_marketing: newFormValues.accepts_marketing,
          create_account: newFormValues.create_account,
          delivery_date: newFormValues.delivery_date ?? "free",
          delivery_timezone: newFormValues.delivery_timezone ?? "free",
          different_billing_address: newFormValues.different_billing_address as Address | undefined,
          email: newFormValues.email ?? "",
          non_face_to_face_receipt:
            newFormValues.delivery_location_code === DeliveryReceiveOption.FaceToFace,
          payment_data: newFormValues.payment_data,
          payment_method: newFormValues.payment_method,
          products: newFormValues.products ?? [],
          set_names: [],
          shipping_address: newFormValues.shipping_address as Address,
          isInvite: newFormValues.isInvite ?? false,
          isLP: newFormValues.isLP ?? false,
          password: newFormValues.password,
          coupon: newFormValues.coupon,
        };
        const res = await validateCart(body);
        const cartErrors = objectValues(res.exception?.errors ?? {}).flatMap((errors) => errors);
        if (cartErrors.length) {
          htmlToast.error(cartErrors);
          return false;
        }
      } catch (e) {
        if (isAxiosError(e)) {
          // 例外の場合の型は微妙に200の場合と違う
          const cartErrors = e.response?.data.exception ?? [];
          if (cartErrors.length) {
            htmlToast.error(cartErrors);
            return false;
          }
        }
      }

      pushGtmEvent({
        event: "click_LP_confirm_button",
        type: "click_LP_confirm_button",
      });

      setPersistentForm(newFormValues);
      return true;
    },
    [setPersistentForm, selectIndex]
  );

  const submit = useCallback(
    async (formValues: NuxtStoreCart["form"]) => {
      setIsSubmitting(true);
      setSubmittingProgress(70);

      try {
        const res = await createOrderPost({
          ...formValues,
          set_names: JSON.stringify(formValues.set_names),
          accepts_marketing: !!formValues.accepts_marketing,
          create_account: !!formValues.create_account,
          delivery_date: formValues.delivery_date ?? "free",
          delivery_timezone: formValues.delivery_timezone ?? "free",
          email: formValues.email ?? "",
          payment_data: formValues.payment_data!,
          payment_method: formValues.payment_method ?? "",
          shipping_address: formValues.shipping_address as Address,
          products: formValues.products ?? [],
          different_billing_address: formValues.different_billing_address as Address | undefined,
          non_face_to_face_receipt:
            formValues.delivery_location_code === DeliveryReceiveOption.FaceToFace,
        });
        setSubmittingProgress(100);

        return res;
      } catch (e) {
        resetSubmittingStatus();
        if (isAxiosError(e)) {
          const errors = e.response?.data.exception ?? [];
          if (errors.length) {
            htmlToast.error(errors);
            return;
          }
        }
      }
    },
    [resetSubmittingStatus, setIsSubmitting, setSubmittingProgress]
  );

  return {
    form,
    persistentForm,
    confirm,
    submit,
  };
};

export function convertFormValueToNuxtStoreForm(
  form: DeepPartial<CheckoutFormValues>,
  selectedProducts: SelectedProducts,
  products: ProductModel[],
  selectIndex?: number
): NuxtStoreCart["form"] {
  const deliveryOptions = form.deliveryOptions;
  const deliveryDate =
    deliveryOptions?.isSpecifiedDate && deliveryOptions?.deliveryDate
      ? format(deliveryOptions?.deliveryDate, "yyyy/MM/dd")
      : undefined;
  const deliveryTimezone = deliveryOptions?.isSpecifiedDate
    ? deliveryOptions?.deliveryTimezone
    : undefined;

  return {
    coupon: form.coupon,
    email: form.credentials?.email ?? "",
    password: form.credentials?.password ?? "",
    accepts_marketing: form.credentials?.acceptsMarketing ?? false,
    products: products
      .filter((product) => selectedProducts[product.variantId] ?? 0 > 0)
      .map((product) => ({
        ...convertProductToNuxtStoreProduct(product),
        quantity: selectedProducts[product.variantId]!,
      })),
    delivery_date: deliveryDate,
    delivery_timezone: deliveryTimezone,
    delivery_location_code: deliveryOptions?.deliveryReceiveOption,
    payment_method: form.paymentData?.paymentMethod,
    payment_data: {
      store: form.paymentData?.store,
      amazon_checkout_session_id: form.paymentData?.amazonCheckoutSessionId,
      gikou_first_name: form.paymentData?.bankFirstName,
      gikou_last_name: form.paymentData?.bankLastName,
      gikou_last_name_kana: form.paymentData?.bankLastNameKana,
      gikou_first_name_kana: form.paymentData?.bankFirstNameKana,
      stripe_token: form.paymentData?.stripeToken,
      last4: form.paymentData?.last4,
    },
    shipping_address: convertAddressToApiAddress(form.shippingAddress),
    create_account: true,
    isLP: true,
    // TODO:isInvite: form.isInvite,
    isInvite: false,
    lp_path: location.pathname,
    selectIndex,
  };
}

export function convertNuxtStoreFormToForm(
  form: NuxtStoreCart["form"]
): DeepPartial<CheckoutFormValues> {
  const shippingAddress = form.shipping_address;

  return {
    credentials: {
      email: form.email,
      password: form.password,
      acceptsMarketing: form.accepts_marketing,
    },
    shippingAddress: {
      lastName: shippingAddress?.last_name,
      firstName: shippingAddress?.first_name,
      zip: shippingAddress?.zip,
      province: shippingAddress?.province,
      city: shippingAddress?.city,
      addressLine1: shippingAddress?.address_line1,
      addressLine2: shippingAddress?.address_line2,
      phone: shippingAddress?.phone,
    },
    deliveryOptions: form.delivery_date
      ? {
          isSpecifiedDate: true,
          deliveryDate: new Date(form.delivery_date),
          deliveryTimezone: form.delivery_timezone as DeliveryTimeZone,
          deliveryReceiveOption: form.delivery_location_code as DeliveryReceiveOption,
        }
      : {
          isSpecifiedDate: false,
          deliveryDate: form.delivery_date ? new Date(form.delivery_date) : undefined,
          deliveryTimezone: form.delivery_timezone as DeliveryTimeZone,
          deliveryReceiveOption: form.delivery_location_code as DeliveryReceiveOption,
        },
    paymentData: {
      paymentMethod: form.payment_method!,
      store: form.payment_data?.store as ConvenienceStore | undefined,
      bankFirstName: form.payment_data?.gikou_first_name,
      bankLastName: form.payment_data?.gikou_last_name,
      bankLastNameKana: form.payment_data?.gikou_last_name_kana,
      bankFirstNameKana: form.payment_data?.gikou_first_name_kana,
      creditCard: undefined,
    },
    coupon: form.coupon ?? "",
    // isInvite: !!form.isInvite,
  };
}

// バグかもしれませんが、「友だち紹介キャンペーン｜BASE FOOD」は invite.vue からそのまま
export type PageFrom =
  | "dietform"
  | "lpform"
  | "gameform"
  | "友だち紹介キャンペーン｜BASE FOOD"
  | "vegetableform";

/**
 * TODO: この関数は、LP_DIET_01などの設定のquantityに依存し、ユーザーが選択した商品の数量によって変わる。
 * LP_DIET_01などの設定が変わったらバグるから、100003などのutm_contentの値をコントロールしやすくするように将来リファクタリングする
 */
export function getUtmContent(
  pageFrom: PageFrom,
  variant: LPRecommendationsVariant,
  selectIndex: number
) {
  const selectedRecommendProducts = LPRecommendations[variant][selectIndex]?.products;
  if (pageFrom === "lpform" && selectedRecommendProducts) {
    if (selectedRecommendProducts[0].quantity === 2) {
      return 100003;
    } else {
      return 100004;
    }
  } else if (pageFrom === "dietform") {
    return 101002;
  }
  return 99999;
}

/**
 * 商品選択が変更されたときにカート情報を同期するカスタムフック
 * APIからカート情報を取得し、ローカルストレージと同期する
 * @param selectedProducts
 * @returns
 */
export const useSyncCart = (selectedProducts: SelectedProducts) => {
  const { form } = useClientCartForm();
  const { data: cart } = useGetParsedCart(
    {
      coupon: form?.coupon ?? "",
      products: objectEntries(selectedProducts).map(([variantId, quantity]) => ({
        variantId,
        quantity: String(quantity ?? 0),
        subscription: false,
      })),
    },
    { placeholderData: keepPreviousData }
  );

  useEffect(() => setClientCartCart(cart), [cart]);
  return cart;
};
