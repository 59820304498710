import { useCallback } from "react";

import clsx from "clsx";

import { Switch } from "@/components/inputs";
import { formatCurrency } from "@/utils/currency";

import styles from "./SubscriptionTogglePanel.module.scss";

type SubscriptionTogglePanelProps = {
  subscriptionEnabled: boolean;
  onChangeSubscriptionEnabled: (value: boolean) => void;
  firstSubscriptionGapPrice: number;
  afterSecondSubscriptionGapPrice: number;
};

export function SubscriptionTogglePanel({
  subscriptionEnabled,
  onChangeSubscriptionEnabled,
  firstSubscriptionGapPrice,
  afterSecondSubscriptionGapPrice,
}: SubscriptionTogglePanelProps) {
  const toggleSubscription = useCallback(() => {
    onChangeSubscriptionEnabled(!subscriptionEnabled);
  }, [subscriptionEnabled, onChangeSubscriptionEnabled]);

  return (
    <div className={clsx("mg__bottom__m mg__bottom__s__pc mg__top__xs", styles.root)}>
      <dl className={styles.toggleSection}>
        <dt className={styles.description}>
          <p className="text__m text__bold mg__bottom__s">継続コースにする</p>
          <p className="text__s text__gray__dary">※カート内のすべての商品に適用されます。</p>
        </dt>
        <dd className={clsx("text__right", styles.toggle)}>
          <Switch value={subscriptionEnabled} onChange={toggleSubscription} />
        </dd>
      </dl>
      <p className="text__red text__bold text__m mg__top__m mg__bottom__s">
        {subscriptionEnabled && (
          <>
            初回20%OFF、
            <br className="clear__sp" />
            2回目以降10%OFF
          </>
        )}
        {!subscriptionEnabled && (
          <>
            初回{formatCurrency(firstSubscriptionGapPrice)}、
            <br className="clear__sp" />
            2回目以降{formatCurrency(afterSecondSubscriptionGapPrice)}お得
          </>
        )}
      </p>
      <p className="text__s">
        1回目お届け以降、いつでも商品内容やお届け日の変更、解約が可能。お手続きがない場合は4週間ごとにお届け。
      </p>
    </div>
  );
}
