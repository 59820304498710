"use client";
import { ChangeEvent, forwardRef, useCallback, useMemo, useState } from "react";

import { Merge, toLocalISOString } from "@/utils";

import { Input, InputProps } from "../Input";

type BaseProps = Omit<InputProps, "type">;
type OwnProps = {
  value?: Date | null;
  defaultValue?: Date | null;
  min?: Date;
  max?: Date;
  onChange?: (date: Date | null, e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (date: Date | null, e: ChangeEvent<HTMLInputElement>) => void;
};

export type DatePickerProps = Merge<BaseProps, OwnProps>;
export type DatePickerRef = HTMLInputElement;

export const DatePicker = forwardRef<DatePickerRef, DatePickerProps>(function DatePicker(
  { value: propsValue, defaultValue, min, max, onChange, onBlur, ...rest },
  ref
) {
  const [innerValue, setInnerValue] = useState<Date | null>(defaultValue ?? null);
  const value = propsValue ?? innerValue;
  const stringValue = value ? toLocalISOString(value).slice(0, 10) : "";

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const date = e.target.valueAsDate;
      onChange?.(date, e);
      setInnerValue(date);
    },
    [onChange]
  );

  const handleBlur = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const date = e.target.valueAsDate;
      onBlur?.(date, e);
    },
    [onBlur]
  );

  const [minStr, maxStr] = useMemo(
    () => [
      min ? toLocalISOString(min).slice(0, 10) : undefined,
      max ? toLocalISOString(max).slice(0, 10) : undefined,
    ],
    [max, min]
  );

  return (
    <Input
      ref={ref}
      type="date"
      value={stringValue}
      onChange={handleChange}
      onBlur={handleBlur}
      min={minStr}
      max={maxStr}
      {...rest}
    />
  );
});
